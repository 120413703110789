import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const selectionColor = 'rgb(52, 59, 70)';
export const selectionActiveColor = 'rgb(13, 125, 217)';
export const selectionShadow = 'inset 0px 0px 12px 2px rgb(20, 79, 143)';

export const SelectionButton = styled(Button)({
    width: '100%',
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    lineHeight: 1.5,
    backgroundColor: selectionColor,
    borderRadius: '0',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: selectionActiveColor,
        borderColor: 'rgb(150, 197, 248)',
        boxShadow: selectionShadow,
    },
    '&:active': {
        boxShadow: selectionShadow,
        backgroundColor: selectionActiveColor,
    },
    '&:focus': {
        boxShadow: selectionShadow,
        backgroundColor: selectionActiveColor,
    },
});
