
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";

export const useRegionQuery = () => {

    async function fetchRegionData() {

        return await axios.get(`${config.API_URL}/operator/regionList`, {
            withCredentials: true,
        })

    }
    return useQuery(
        {
            queryKey: ['regionData'],
            queryFn: fetchRegionData,
            staleTime: 1000 * 60 * 60 * 6, // 6 hours
        }




    )

}