

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config';
import { getCookie } from '../../../functions/getCookie';


/*
hospitalList: {
    '75322332525asd5122':{
        Lat: 32.12512451,
        Lng: 28.2312323,
    },
    '653222332125':{
        Lat: 33.1123531,
        Lng: 26.18562323,
    },

}
or
hospitalArray: [
    {
        _id: '75322332525asd5122',
        Lat: 32.12512451,
        Lng: 28.2312323,
    },
    {
        _id: '653222332125',
        Lat: 33.1123531,
        Lng: 26.18562323,
    },
]
*/


export const useMapboxDirectionsQuery = ({ hotel, selectedValue }) => {


    async function fetchDirections({ queryKey }) {

        const [_key, hotel] = queryKey;
        return await axios.post(
            `${config.API_URL}/mapbox/getDirections`,
             hotel
            ,
            {
                withCredentials: true,
            }
        );

    }

    return useQuery(
        {
            queryKey: ['directions',
                    {...hotel}
            ],
            queryFn: fetchDirections,
            enabled: !!hotel && hotel?._id === selectedValue?._id,
            staleTime: 1000 * 60 * 60 * 24 * 30,
            refetchInterval: false,
            retry: 0,
        }
    )
}