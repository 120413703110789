import React from 'react';


import { selectionColor, selectionActiveColor, SelectionButton } from './SelectionButton';


export const GeocodeResultsButtons = ({geocodeQuery, handleGeocodeSelection, selectedGeocode}) => {


    return (<>
                {geocodeQuery.data?.data?.features?.map((feature) => {
                    return <SelectionButton onClick={handleGeocodeSelection(feature)}
                        sx={{ color: 'white', background: selectedGeocode?.id === feature.id ? selectionActiveColor : selectionColor }} key={feature.id}>{feature.text_tr}</SelectionButton>;
                })}
    </>);
}
