import React, { useState } from 'react';

import { TextField, useTheme } from '@mui/material';


const TextFilter = ({ onFilter, labelName, props, dense }) => {
    const [selectedFilterVal, setselectedFilterVal] = useState('');

    const handleFieldChange = (e) => {
        setselectedFilterVal(e.target.value);
        onFilter({
            value: e.target.value
        });
    };

    const theme = useTheme();

    return (
        <div className='CustomFilterContainer' >
            <TextField
            InputLabelProps={{ shrink: true }}
                label={labelName}
                value={selectedFilterVal}
                onChange={handleFieldChange}
                sx={ {width: '200px',
                border: `1px solid ${theme.palette.shadow[1]}`,
                borderRadius: '8px',
                background: theme.palette.dark[3],
                boxShadow: `inset 0px 0px 12px 8px ${theme.palette.shadow[2]}`,
                ' .MuiInputLabel-root': {
                    color: theme.palette.mainColor.Light,
                    fontWeight:'bold',
                },
                '&  .MuiInputBase-root:focus': {
                    background:'none !important',
                    outline:'none !important',
                    border:'none !important'
                },
                '&  .MuiInputBase-input:focus': {
                    outline:'none',
                    border:'none'
                },
                '& .MuiInputBase-input': {
                    padding: (dense === true)?
                    '0.8rem 1.25rem 0.5rem 1.25rem': '1rem',
                }



            } }
            />


        </div>
    );
};

export default TextFilter;
