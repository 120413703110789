import remToPx from '../../../functions/remToPx';
import { ResponsivePie } from '@nivo/pie';
import { linearGradientDef } from '@nivo/core';
import { NivoTheme } from '../NivoTheme';

export const TotalCasePieChart = ({index, activeChart, seriesAll, ListTotals}) => {
    return <div
        className='ResponsivePieArc'
        style={{
            height: '100%',
            width: '34%',
            right: 0,
            position: 'fixed',
            display: 'block',
            visibility: (index === activeChart) ? 'visible' : 'hidden',
            background: 'linear-gradient(90deg, rgba(34, 34, 37, 1) 0%, rgb(37, 46, 60) 100%)',
            paddingTop: `${remToPx(125 / 16)}px`,
            paddingBottom: `${remToPx(75 / 16)}px`,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>

        <ResponsivePie
            animate={true}

            theme={NivoTheme}
            data={seriesAll[0][1]}
            colors={{ datum: 'data.color' }}
            margin={{ top: remToPx(25 / 16), right: remToPx(35 / 16), bottom: remToPx(150 / 16), left: remToPx(35 / 16) }}
            innerRadius={2.75 / remToPx(0.5)}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={18}
            activeInnerRadiusOffset={0}
            borderWidth={1}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#fff"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor="#fff"
            valueFormat={value => `${value} - ${(value / ListTotals[4] * 100).toFixed(2)}%`}

            motionConfig={{
                mass: 15,
                tension: 455,
                friction: 100,
                clamp: true,
                precision: 0.01,
                velocity: 0
            }}

            transitionMode='innerRadius'


            defs={[
                linearGradientDef('gradient', [
                    { offset: 0, color: 'inherit' },
                    { offset: 100, color: 'inherit', opacity: 0.35 },
                ],
                    { gradientTransform: 'rotate(45 0.5 0.5)' }),
            ]}
            fill={[{ match: '*', id: 'gradient' }]}

            layers={[({ centerX, centerY, radius }) => {

                return (
                    <g>
                        <defs>

                            <radialGradient id="radInnerGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                                <stop offset="85%" style={{ stopColor: "rgb(28, 28, 28)", stopOpacity: 0.8 }} />
                                <stop offset="90%" style={{ stopColor: "rgb(24, 128, 20)", stopOpacity: 1 }} />
                            </radialGradient>
                        </defs>

                        <circle cx={centerX} cy={centerY} r={radius * (2.75 / remToPx(0.5))} fill="url(#radInnerGradient)" />

                    </g>
                );

            },



                'arcs', 'arcLabels', 'arcLinkLabels', 'legends',
            ({ centerX, centerY }) => {
                return (
                    <>
                        <text
                            className='pieCenterText'
                            x={centerX}
                            y={centerY}

                            textAnchor="middle"
                            dominantBaseline="middle"
                            style={{
                                fill: 'white',
                                textShadow: '0px 0px 20px rgba(31, 128, 255, 0.88)',
                            }}
                        >
                            {ListTotals[4]}
                        </text>



                    </>
                );

            }
            ]} />


    </div>;
}
