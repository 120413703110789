import { useState } from 'react';

import {
    Button,

    Typography
} from '@mui/material';
import TextLanguage from './data/TextLanguage.json';
import MenuIcon from '@mui/icons-material/Menu';
import { PageMenu } from './PageMenu';
import { LanguageSelector } from './LanguageSelector';

import CompLogo from '../img/RBKLogo.png';

export const AppBar = ({ lang, setlang, currentPage }) => {

    const [anchorEl, setAnchorEl] = useState(null);



    return <div className='AppBar'>
        <Button
            aria-controls={'routerMenu'}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{
                m: '0px 0px 0px 0px',
                padding: '0px 0px 0px 0px',
                height: '48px',
                alignItems: 'center',
                alignSelf: 'center'
            }}
            onClick={(event) => {
                setAnchorEl(event.currentTarget);
            }}
        >
            <MenuIcon />

        </Button>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>


            <LanguageSelector
                lang={lang}
                setlang={setlang}
            />
        <PageMenu
                lang={lang}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                currentPage={currentPage}
            />


                <div className='CompLogoBackground'>
                    <img src={CompLogo} alt="CompLogo" style={{ height: '2.25rem', width: 'auto', }} />
                </div>


        </div>

    </div>;
}

