
import { useReducer, useEffect, useRef, useState } from 'react';

import {
    MenuItem, Typography, Button


} from '@mui/material';
import { FormField } from '../../StyledComponents/FormField';
import { useCreateHotelMutation } from '../hooks/useCreateHotelMutation';

import { useGeocodeQuery } from '../hooks/useGeocodeQuery';
import GeocodeMapComponent from './GeocodeMapComponent';
import { GeocodeResultsButtons } from './GeocodeResultsButtons';
import { NewPatientButton } from '../../StyledComponents/NewPatientButton';
import { GeocodeField } from './GeocodeField';
import TextLanguage from '../../data/TextLanguage.json';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD':
            return {
                ...state,
                [action.fieldName]: action.payload,
            };
        default:
            return state;
    }
}

const initialState = {
    "Name": '',
    "Area": '',
    "Phone": '',
    "Fax": '',
    "Reference": '',
    "Contract": '',
    "ContractPhoneNotes": '',
    "Region": '',
    "Lat": 36.8685887,
    "Lng": 30.6484849,
    "NameComm": '',
    "ReferenceComm": '',
    "ContractComm": '',
    "ContractPhoneNotesComm": '',
    "AreaComm": ''
};

const dragGeojson = {
    'type': 'FeatureCollection',
    'features': [
        {
            'type': 'Feature',
            'geometry': {
                'type': 'Point',
                'coordinates': [0, 0] // center of the map on init
            }
        }
    ]
};

export const HotelCreateForm = ({ setsnackbarStatus, hospDataQuery, regionDataQuery, setModalOpen, mapboxAPIKey, lang }) => {

    const map = useRef(null);

    const hotelCreateMutation = useCreateHotelMutation({ setModalOpen, setsnackbarStatus });

    const [hotelUpdater, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (fieldName) => (e) => {

        dispatch({
            type: 'SET_FIELD',
            fieldName: fieldName,
            payload: e.target.value,
        });

    };

    const handlePasteClipboard = () => {
        navigator.clipboard.readText().then(text => {
            const [lat, lng] = text?.split(', ');
           if (text?.split(', ')?.length === 2 && !isNaN(parseFloat(lat)) && !isNaN(parseFloat(lng))){
             dispatch({
                 type: 'SET_FIELD',
                 fieldName: 'Lat',
                 payload: parseFloat(lat),
             });
             dispatch({
                 type: 'SET_FIELD',
                 fieldName: 'Lng',
                 payload: parseFloat(lng),
             });

           } else {
                setsnackbarStatus({ open: true, message: 'Clipboard Content should be (lat, long)', severity: 'error' });
           }
        });
    }


    //const hotelUpdateMutation = useUpdateHotelMutation({ searchMode:true ,setSelectedValue, setFormModalOpen,setsnackbarStatus  });



    const [geocodeSearchInput, setGeocodeSearchInput] = useState('');
    const [debouncedSearchInput, setDebouncedSearchInput] = useState('');
    //const [debouncedName, setDebouncedName] = useState(hospitalUpdater.Name);
    const debouncedSearchRef = useRef(null);

    useEffect(() => {
        debouncedSearchRef.current && clearTimeout(debouncedSearchRef.current);

        debouncedSearchRef.current = setTimeout(() => {
            setDebouncedSearchInput(geocodeSearchInput);
        }, 400);
        return () => clearTimeout(debouncedSearchRef.current);

    }, [geocodeSearchInput]);

    const handleSubmitEdit = () => {
        hotelCreateMutation.mutate({ updaterData: hotelUpdater });
    }

    const [selectedGeocode, setSelectedGeocode] = useState(null);

    const geocodeQuery = useGeocodeQuery({ searchInput: debouncedSearchInput, region: hotelUpdater.Region, type: 'hotel' });
    const handleGeocodeSelection = (feature) => (e) => {


        dispatch({
            type: 'SET_FIELD',
            fieldName: 'Lat',
            payload: feature.center[1],
        });
        dispatch({
            type: 'SET_FIELD',
            fieldName: 'Lng',
            payload: feature.center[0],
        });


        setSelectedGeocode(feature);

    };

    const handleCenterButton = () => {

        const center = map?.current?.getCenter();
        dispatch({
            type: 'SET_FIELD',
            fieldName: 'Lat',
            payload: center.lat,
        });
        dispatch({
            type: 'SET_FIELD',
            fieldName: 'Lng',
            payload: center.lng,
        });


    }

    useEffect(() => {

        dragGeojson.features[0].geometry.coordinates = [hotelUpdater.Lng, hotelUpdater.Lat];
        map?.current?.getSource('dragpoint')?.setData(dragGeojson);

    }, [hotelUpdater.Lng, hotelUpdater.Lat])

    return <div className='HotelEditFormContainer'>

        <div style={{ display: 'flex', justifyContent: 'center', height: '100%', padding: '0px' }}>

            <div className='CreateEntryFormContainer' >
                <Typography variant='h6' sx={{ fontSize: '1.75rem', color: 'white', fontWeight: 'bolder', marginBottom: '0.75rem' }}>
                    {TextLanguage['NewHotelEntry'][lang]}
                </Typography>
                <div className='form-row'>

                    <FormField
                        label={TextLanguage['Hotel Name'][lang]}
                        value={hotelUpdater.Name}
                        onChange={handleFieldChange('Name')} />
                    <FormField
                        label={TextLanguage['Phone'][lang]}
                        value={hotelUpdater.Phone}
                        onChange={handleFieldChange('Phone')} />
                </div>

                <div className='form-row'>

                    <FormField

                        id="select-contractedHosp"
                        select
                        value={hotelUpdater.Contract}
                        onChange={handleFieldChange('Contract')}
                        defaultValue={hotelUpdater.Contract}
                        helperText={TextLanguage['SelectContractedHospital'][lang]}
                    >
                        <MenuItem value={null}>
                            {TextLanguage['None'][lang]}
                            </MenuItem>
                        {hospDataQuery?.data?.data?.map((option) => (
                            <MenuItem key={`Cont-Hosp-Opt-${option?.Name}`} value={option?.Name}>
                                {option?.Name}
                            </MenuItem>
                        ))}
                    </FormField>

                </div>
                <div className='form-row'>

                    <FormField
                        id="select-Region"
                        select
                        value={hotelUpdater.Region}
                        onChange={handleFieldChange('Region')}
                        defaultValue={hotelUpdater.Region}
                        helperText={TextLanguage['SelectRegion'][lang]}
                    >
                        <MenuItem value={null}>
                        {TextLanguage['None'][lang]}
                        </MenuItem>
                        {regionDataQuery?.data?.data?.map((option) => (
                            <MenuItem key={`Region-Opt-${option?.Region}`} value={option?.Region}>
                                {option?.Region}
                            </MenuItem>
                        ))}
                    </FormField>

                </div>
                <div className='form-row'>

                    <FormField
                        label={TextLanguage['Fax'][lang]}
                        value={hotelUpdater.Fax}
                        onChange={handleFieldChange('Fax')} />
                    <FormField
                        label={TextLanguage['Reference'][lang]}
                        value={hotelUpdater.Reference}
                        onChange={handleFieldChange('Reference')} />
                </div>
                <div className='form-row'>

                    <FormField
                        label={TextLanguage['Notes'][lang]}
                        multiline
                        rows={2}
                        value={hotelUpdater.NameComm}
                        onChange={handleFieldChange('NameComm')} />
                </div>
                <div className='form-row'>

                    <FormField
                        label={TextLanguage['Latitude'][lang]}
                        value={hotelUpdater.Lat}
                        onChange={handleFieldChange('Lat')} />
                    <FormField
                        label={TextLanguage['Longitude'][lang]}
                        value={hotelUpdater.Lng}
                        onChange={handleFieldChange('Lng')} />
                    <Button onClick={handlePasteClipboard} sx={{
                        background: 'rgb(49,80,125)', padding: '0px', margin: '0px',
                        '&:hover': { background: 'rgb(53, 94, 156)' }
                    }}>
                        <ContentPasteIcon sx={{ color: 'white', fontSize: '2.4rem' }} />
                    </Button>

                </div>

                <div style={{ marginTop: '40px', marginBottom: '0px', alignItems: 'center' }}>
                    <NewPatientButton
                        disabled={hotelCreateMutation.isPending || !hotelUpdater.Name || !hotelUpdater.Region || !hotelUpdater.Phone || !hotelUpdater.Lat || !hotelUpdater.Lng }
                        sx={{
                            fontSize: '1rem', color: 'white', m: '5px', padding: '5px 15px 5px 15px', width: '150px', fontWeight: 'bold',
                            background: 'rgb(52, 59, 70)'
                        }}
                        onClick={handleSubmitEdit}>
                        Submit
                    </NewPatientButton>
                </div>

            </div>

            <div style={{ width: '100%', height: '100%', position: 'relative', borderLeft: '0.5rem solid rgb(66, 66, 66)' }}>
                {<div className='GeocodeSelectionContainer'>

                    <div style={{ padding: '16px', width: '100%', gap: '16px', display: 'flex', flexDirection: 'row' }}>
                        <GeocodeField
                            label="Search"
                            value={geocodeSearchInput}
                            onChange={(e) => setGeocodeSearchInput(e.target.value)}
                        />


                        <Button
                            onClick={handleCenterButton}
                            sx={{ padding: '0px', background: 'rgba(77, 77, 77, 0.5)' }}>
                            <AddLocationAltIcon sx={{
                                color: 'rgb(125,188,255)', fontSize: '2.4rem', margin: '0px', padding: '0px'
                            }} />

                        </Button>



                    </div>

                    <div style={{ maxHeight: '16rem', overflowY: 'auto', width: '100%' }}>
                        <GeocodeResultsButtons
                            geocodeQuery={geocodeQuery}
                            handleGeocodeSelection={handleGeocodeSelection}
                            selectedGeocode={selectedGeocode}
                        />
                    </div>
                </div>
                }

                <GeocodeMapComponent
                    geocodeQuery={geocodeQuery}
                    selectedGeocode={selectedGeocode}
                    mapboxAPIKey={mapboxAPIKey}
                    dispatch={dispatch}
                    map={map}

                />
            </div>

        </div>

    </div>;
}

