
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../config.js";


export const useInsuranceCompanyListQuery = () => {



    async function fetchInsuranceCompanyList() {
        return await axios.get(
            `${config.API_URL}/operator/insuranceCompanyList`,  // Pass the filtersData directly as the second argument
            {
                withCredentials: true
            }
        );
    }

    return useQuery(
        {
            queryKey: ['insCoList'],
            queryFn: fetchInsuranceCompanyList,
            staleTime: Infinity,
            retry: (failureCount, error) => {

                if (failureCount > 2) {
                    return false;
                }
            }
        }
    )
}