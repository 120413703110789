import React from 'react';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
    Box,
    Button, Avatar, Divider,
    Typography
} from '@mui/material';
import LocalHospitalRoundedIcon from '@mui/icons-material/LocalHospitalRounded';
import LocalPharmacyOutlinedIcon from '@mui/icons-material/LocalPharmacyOutlined';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import DentistIcon from '../../img/DentistIcon';
import MedCenterIcon from '../../img/MedCenterIcon';
import DoctorIcon from '../../img/DoctorIcon';
import EditIcon from '@mui/icons-material/Edit';
import remToPx from '../../functions/remToPx';
import { AccordionDetails } from '../StyledComponents/AccordionDetails';
import { AccordionSummary } from '../StyledComponents/AccordionSummary';
import { Accordion } from '../StyledComponents/Accordion';

import TextLanguage from '../data/TextLanguage.json';

import { interpolateColor } from '../../functions/interpolateColor'

export const HospitalAccordion = ({
    lang,
    data,
    expanded, expandAll, selectedValue,
    handleAccordChange,
    accordpanelIDInstant,
    contactGlow,
    setOpenModal, setaccordpanelIDInstant, durationRange, regionalMode,

}) => {

    // Disabled debounce on accordion hover
   // const accordionFlyWaitTime = 250;
    //const leaveTimeoutRef = useRef(null);
    //const [isMouseInside, setisMouseInside] = useState(false); // Inside accordion

    const assignAccordColor = (i) => {
        if (showAsContractedHRef) { return '#052306bf' }
        else if (accordpanelIDInstant === i) { return 'rgba(4, 46, 98, 0.33)' }
        else { return 'rgba(5,5,5,0.2)' }
    }

    const handleAccordionEnter = (panel) => (event, newExpanded) => {


        setaccordpanelIDInstant(panel);
        //setaccordpanelID(panel);
/*
        setisMouseInside(true);
        console.log(isMouseInside);

                leaveTimeoutRef.current = setTimeout(() => {
                    if (isMouseInside) {
                        setaccordpanelID(panel);

                    };

                }, accordionFlyWaitTime);
*/

    };

    const handleAccordionLeave = (panel) => (event, newExpanded) => {
        //setisMouseInside(false);
        setaccordpanelIDInstant(-1);
        //clearTimeout(leaveTimeoutRef.current);
    };

    const handleModalOpen = (index) => {
        setOpenModal(index);
    };

    const duration = data?.directionAPI?.routes[0]?.duration;
    const DurationTime = new Date(duration * 1000);
    const parsedDuration = regionalMode ? '' : `${DurationTime?.getUTCHours() ? `${DurationTime?.getUTCHours()}${TextLanguage["HourShortcut"][lang]}` : ''} ${DurationTime?.getUTCMinutes()}${TextLanguage["MinuteShortcut"][lang]}`
    const showAsContractedHRef = (selectedValue && selectedValue.Contract && (data?.MedGroup !== '') && (selectedValue.Contract.includes(data.MedGroup) || selectedValue.Contract === data.Name))

    return (<>
        <Divider variant="middle" className="accordDivide" />
        <Accordion className="accordClass"
            expanded={ data.Notes &&   (expanded === data.index) || ((expandAll && data?.Notes)) || (showAsContractedHRef && data?.Notes) }
            key={data.index + 1}
            onChange={handleAccordChange(data.index)}
            onMouseEnter={handleAccordionEnter(data.index)}
            onMouseLeave={handleAccordionLeave(data.index)}
        >

            <AccordionSummary
                key={data.index + 1}
                sx={{ overflow: 'visible', color: showAsContractedHRef ? 'hsl(131, 96%, 66%)' : 'white' }}
                bgcolor1={assignAccordColor(data.index)}
                accordHeight={(accordpanelIDInstant === data.index) ? 65 : 55}
                bgcolor2={(accordpanelIDInstant === data.index) ? 'rgba(255,115,55,0.25)' : 'rgba(255,115,55,0.05)'}
                expandIcon={<ArrowForwardIosSharpIcon sx={{
                    fontSize: '1.5rem',
                    color: showAsContractedHRef ? (data.Notes ? 'hsl(131, 96%, 66%)' : 'hsl(131, 66%, 20%)') : ((data.Notes) ? 'white' : 'rgba(34, 34, 37, 0.9)')
                }} />}
                aria-controls="panel-content" id={`panel-header${data.index}`}
                className='accordSumClass'>

                <Box sx={{ padding: 0.4 }}>
                    {HospitalIconPicker(data.Type)}
                </Box>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginRight: '6px' }}>

                    <Typography sx={{fontSize: '1.2rem', fontWeight: 'bold', marginTop: 0.4, marginLeft: 1, padding: 0.5}}>
                        {data.Name}
                    </Typography>
                    {(accordpanelIDInstant !== data.index) ?
                        <Typography sx={{fontSize: '1.2rem', fontWeight: 'bold', marginTop: 0.4, marginLeft: 1, padding: 0.5, color: interpolateColor(duration, durationRange[0], durationRange[1], "hsl(98, 99%, 44%)", "hsl(0, 100%, 44%)")}}>
                            {`${parsedDuration}`}
                        </Typography>
                        : null
                    }
                </div>

                {
                    regionalMode ?
                        (accordpanelIDInstant === data.index) &&
                            <Button sx={{ zIndex: 1250 }} onClick={() => handleModalOpen(data.index)}>
                                <EditIcon sx={{ color: "white", height: 25, width: 25 }} />
                            </Button>
                        :
                        ((accordpanelIDInstant === data.index)) &&
                            (<Button className='ContactButton' sx={{ overflow: 'visible', position: 'absolute', right: 10 }} onClick={() => handleModalOpen(data.index)}>
                                <Avatar sx={{ bgcolor: 'rgba(50,50,50,1)', zIndex: 10, height: 35, width: 35 }}>
                                    <PhoneEnabledIcon sx={{
                                        color: "rgba(33,194,27,0.7)", zIndex: 8, height: 25, width: 25
                                    }} />
                                </Avatar>
                                {
                                    // <div style={{backgroundColor:'hsla(213, 76%, 66%, 0.79)',width:255,height:45,position:'absolute',zIndex:5,left:'5px',borderRadius:40,boxShadow:'0px 0px 25px 8px hsla(213, 76%, 66%, 0.79)'}}></div>
                                    <div ref={contactGlow} className='ContactButtonGlow' style={{ position: 'absolute', width: 5, height: 5, zIndex: 5, borderRadius: 100, boxShadow: '0px 0px 15px 12px rgba(33,194,27,0.7)', transitionProperty: 'width, height', transitionDuration: '500ms', transitionTimingFunction: 'ease-in-out' }}></div>}
                            </Button>)}
            </AccordionSummary>
            <AccordionDetails bgcolor={assignAccordColor(data.index)}>
                <Typography sx={{ color: 'white' }}>
                    {
                        // Parse these for formatting (Phone: (some of them have Name:) No#1/No#2 ,Fax: )
                        /*
                        <span style={{ color: 'lightblue' }}> {data.Phone ? (`Phone: ${data.Phone}`) : null}   </span>
                        {data.Phone && <br />}
                        <span style={{ color: 'lightblue' }}> {data.Fax ? (`Fax: ${data.Fax}`) : null}   </span>
                        {data.Fax && <br />}
                        <span style={{ color: 'white' }}> {data.Email ? (`Email: ${data.Email}`) : null}  </span>
                        {data.Email && <br />}
                        */
                    }
                    <Typography style={{ color: 'white' }}> {data?.Notes ? data.Notes : ''}  </Typography>

                </Typography>
            </AccordionDetails>
        </Accordion>

    </>);
}

function HospitalIconPicker(Type) {
    return Type === 'Hospital' ?
        (<LocalHospitalRoundedIcon style={{ alignSelf: 'center', color: 'red', width: remToPx(36 / 16), height: remToPx(36 / 16) }} />)
        :
        Type === 'Clinic' ?
            (<LocalPharmacyOutlinedIcon style={{ alignSelf: 'center', color: 'rgba(33,194,27,0.7)', width: remToPx(36 / 16), height: remToPx(36 / 16) }} />)
            : Type === 'MedCenter' ? (
                <div style={{ alignSelf: 'center', color: 'rgba(255,255,0,0.4)' }}>
                    <MedCenterIcon style={{ alignSelf: 'center', }} />
                </div>
            ) : Type === 'Dentist' ? (
                <div style={{ alignSelf: 'center', color: 'rgba(255,255,255)' }}>
                    <DentistIcon style={{ alignSelf: 'center' }} />
                </div>
            ) : Type === 'Doctor' ? (

                <div style={{ color: 'rgb(125,188,255)' }}>
                    <DoctorIcon />
                </div>
            ) : null;
}

