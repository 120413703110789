import React, { useRef, useState } from 'react';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
    Box,
    Button, Avatar, Divider,
    Typography
} from '@mui/material';
import LocalHospitalRoundedIcon from '@mui/icons-material/LocalHospitalRounded';
import LocalPharmacyOutlinedIcon from '@mui/icons-material/LocalPharmacyOutlined';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import DentistIcon from '../../img/DentistIcon';
import MedCenterIcon from '../../img/MedCenterIcon';
import DoctorIcon from '../../img/DoctorIcon';
import EditIcon from '@mui/icons-material/Edit';
import remToPx from '../../functions/remToPx';
import { AccordionDetails } from '../StyledComponents/AccordionDetails';
import { AccordionSummary } from '../StyledComponents/AccordionSummary';
import { Accordion } from '../StyledComponents/Accordion';

import TextLanguage from '../data/TextLanguage.json';


import { interpolateColor } from '../../functions/interpolateColor'


export const HotelAccordionRegional = ({
    lang,
    data,
    expanded, expandAll,
    handleAccordChange,
    accordpanelIDInstant,
    contactGlow, openModal,
    setOpenModal, setaccordpanelIDInstant, setaccordpanelID, durationRange, regionalMode,
    openContactModal, setOpenContactModal

}) => {




    // Disabled debounce on accordion hover
    // const accordionFlyWaitTime = 250;
    //const leaveTimeoutRef = useRef(null);
    //const [isMouseInside, setisMouseInside] = useState(false); // Inside accordion

    const showAsContractedHRef = useRef(false);

    const assignAccordColor = (i) => {
        if (showAsContractedHRef.current) { return '#052306bf' }
        else if (accordpanelIDInstant === i) { return 'rgba(4, 46, 98, 0.33)' }
        else { return 'rgba(5,5,5,0.2)' }
    }

    const handleAccordionEnter = (panel) => (event, newExpanded) => {
        setaccordpanelIDInstant(panel);
    };

    const handleAccordionLeave = (panel) => (event, newExpanded) => {
        setaccordpanelIDInstant(-1);
    };


    const handleModalOpen = (index) => {
        setOpenModal(index);
    };


    return <>
        <Divider variant="middle" className="accordDivide" />
        <Accordion className="accordClass"
            expanded={(expanded === data.index)}
            key={data.index + 1}
            onChange={handleAccordChange(data.index)}
            onMouseEnter={handleAccordionEnter(data.index)}
            onMouseLeave={handleAccordionLeave(data.index)}
        >
            {showAsContractedHRef.current = (data && data.Contract && (data.Contract.includes(data.MedGroup) || data.Contract === data.Name))}
            <AccordionSummary
                key={data.index + 1}
                sx={{ overflow: 'visible', color: (data && data.Contract && (data.Contract.includes(data.MedGroup) || data.Contract === data.Name)) ? 'hsl(131, 96%, 66%)' : 'white' }}
                bgcolor1={assignAccordColor(data.index)}
                accordHeight={(accordpanelIDInstant === data.index) ? 65 : 55}
                bgcolor2={(accordpanelIDInstant === data.index) ? 'rgba(255,115,55,0.25)' : 'rgba(255,115,55,0.05)'}
                expandIcon={<ArrowForwardIosSharpIcon sx={{
                    fontSize: '1.5rem',
                    color:  'white'
                }} />}
                aria-controls="panel-content" id={`panel-header${data.index}`}
                className='accordSumClass'>


                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginRight: '6px' }}>

                    <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold', marginTop: 0.4, marginLeft: 1, padding: 0.5 }}>
                        {data.Name}
                    </Typography>

                </div>

                {

                    (accordpanelIDInstant === data.index) &&
                        <Button sx={{ zIndex: 1250 }} onClick={() => handleModalOpen(data.index)}>
                            <EditIcon sx={{
                                color: "white", height: 25, width: 25
                            }} />
                        </Button>


                }


            </AccordionSummary>
            <AccordionDetails bgcolor={assignAccordColor(data.index)}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>

                    {data.Phone &&
                        <Typography sx={{ color: 'red', gap: '4px' }}>{`${TextLanguage["Phone"][lang]}:`}
                            <span style={{ color: 'white' }}>{` ${data.Phone}`}</span>
                        </Typography>}

                    {data.Fax &&
                        <Typography sx={{ color: 'red', gap: '4px' }}>{`${TextLanguage["Fax"][lang]}:`}
                            <span style={{ color: 'white' }}>{` ${data.Fax}`}</span>
                        </Typography>}
                    {data.Reference &&
                        <Typography sx={{ color: 'red', gap: '4px' }}>{`${TextLanguage["Reference"][lang]}:`}
                            <span style={{ color: 'white' }}>{` ${data.Reference}`}</span>
                        </Typography>}

                    {data?.NameComm && <Typography>{data?.NameComm}</Typography>}
                    {data?.AreaComm && <Typography>{data?.AreaComm}</Typography>}
                    {data?.PhoneComm && <Typography>{data?.PhoneComm}</Typography>}
                    {data?.FaxComm && <Typography>{data?.FaxComm}</Typography>}
                    {data?.ReferenceComm && <Typography>{data?.ReferenceComm}</Typography>}
                    {data?.ContractComm && <Typography>{data?.ContractComm}</Typography>}
                    {data?.ContractPhoneNotes && <Typography>{data?.ContractPhoneNotes}</Typography>}
                    {data?.ContractPhoneNotesComm && <Typography>{data?.ContractPhoneNotesComm}</Typography>}

                </div>
            </AccordionDetails>
        </Accordion>

    </>;
}


