
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";
import { getCookie } from "../../../functions/getCookie.jsx";

export const useGeocodeQuery = ({ searchInput, region, type }) => {

    async function fetchGeocodeData({ queryKey }) {
        const [_key, params] = queryKey;
        return await axios.get(
            `${config.API_URL}/mapbox/geocode?address=${params?.searchInput}&region=${params?.region}&type=${params?.type}`,
            {
                withCredentials: true,
            }
        );

    }

    return useQuery(
        {
            queryKey: ['geocode',
            {searchInput: searchInput, region: region, type: type}
            ],
            queryFn: fetchGeocodeData,
            enabled: !!searchInput && typeof searchInput === 'string' &&  searchInput?.length > 3,
            staleTime: Infinity,
            refetchInterval: false,
            retry: 0,
        }
    )

}


