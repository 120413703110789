import TextLanguage from '../../data/TextLanguage.json';
import remToPx from '../../../functions/remToPx';
import { ResponsiveLine } from '@nivo/line';
import { ResponsivePie } from '@nivo/pie';
import { linearGradientDef } from '@nivo/core';
import { NivoTheme } from '../NivoTheme';
import { line, curveMonotoneX, area } from 'd3-shape';
import { rgb } from 'd3-color';
import { scaleSequential } from 'd3-scale';
import { interpolateWarm, interpolateCividis, interpolatePlasma, interpolatePiYG ,interpolateCool,interpolateBlues } from 'd3-scale-chromatic';



import React, { useState } from 'react';
import { xDateFormatter } from '../functions/xDateFormatter';





export const BreakdownCaseNivoChart = ({
    ListTotals,
    seriesAll, dataProcessed, activeChart, setTooltip,
    setHighlightedId, highlightedId, tooltip,
     pieAngle, dateRangeTitles, filterDateRange, xtickMax,
     lang,
     height,
    legendToggle,
    setvisibleBool,
    visibleBool,

      index,  chartTypeToggle
}) => {

    const lineGenerator = (line()
.x(d => d.x)
.y(d => d.y)
.curve(curveMonotoneX));


const areaGenerator = area()
.x(d => d.x)
.y0(height - remToPx(355 / 16)) // Set the bottom line for the area 125 = sum of top,bottom margins // no idea where 150 is from
.y1(d => d.y)
.curve(curveMonotoneX); // This defines the top boundary of the area.

const data = seriesAll[index][chartTypeToggle ? 1 : 0];

    let TotalVar = 1;

    // ListTotals
    // Replace Total Var with ListTotals[index-1]
    /*
   switch (index) {
        case 1:
            TotalVar = HospListTotal;
            break;
        case 2:
            TotalVar = RefusalListTotal;
            break;
        case 3:
            TotalVar = RegionListTotal;
            break;
        case 4:
            TotalVar = TCListTotal;
            break;
        case 5:
            TotalVar = ICListTotal;
            break;
        case 6:
            TotalVar = OperatorListTotal;
            break;
        default:
            TotalVar = 1;
    };
*/
    TotalVar = ListTotals[index - 1];
    //console.log(seriesAll[0][0][0])
    return (
        dataProcessed && seriesAll[0][0][0].data ?
            <>
                <div
                    className='ResponsivePieArc'
                    style={{
                        height: '100%',
                        width: '100%',
                        position: 'fixed',
                        display: 'block',
                        visibility: (index === activeChart) ? 'visible' : 'hidden',
                        background: 'linear-gradient(90deg, rgba(34, 34, 37, 1) 0%, rgb(37, 46, 60) 100%)',
                        paddingTop: `${remToPx(125 / 16)}px`,
                        paddingBottom: `${remToPx(90 / 16)}px`,

                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>


                    {
                        // Other Charts
                        chartTypeToggle ?
                            (<ResponsivePie

                                animate={true}
                                theme={NivoTheme}
                                data={data.reverse()}
                                colors={{ datum: 'data.color' }}

                                margin={{ top: remToPx(50 / 16), right: remToPx(122 / 16), bottom: remToPx(125 / 16), left: remToPx(122 / 16) }}
                                innerRadius={2.75 / remToPx(0.5)}
                                padAngle={0.6}
                                startAngle={pieAngle}
                                cornerRadius={2}
                                sortByValue={true}

                                activeOuterRadiusOffset={18}

                                /*
                                borderColor={{from: 'color',
                                modifiers: [
                                    [
                                        'darker',
                                        1
                                    ]
                                ]}}
                                */
                                borderWidth={0}
                                valueFormat={value => `${(value / TotalVar * 100).toFixed(2)}%`}
                                arcLinkLabelsSkipAngle={8}
                                arcLinkLabelsTextColor="#ffffff"
                                arcLinkLabelsThickness={3}
                                arcLinkLabelsColor={{ from: 'color', modifiers: [] }}
                                arcLabelsSkipAngle={18}
                                arcLabelsTextColor={{
                                    from: 'color',
                                    modifiers: [
                                        [
                                            'darker',
                                            7
                                        ]
                                    ]
                                }}

                                motionConfig={{
                                    mass: 15,
                                    tension: 455,
                                    friction: 100,
                                    clamp: true,
                                    precision: 0.01,
                                    velocity: 0
                                }}

                                transitionMode='innerRadius'

                                legends={(legendToggle) ? [
                                    {
                                        anchor: 'top-right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: -72,
                                        translateY: 0,
                                        itemsSpacing: 0,
                                        itemDirection: 'left-to-right',
                                        itemWidth: 222,
                                        itemHeight: 30,
                                        itemOpacity: 0.65,
                                        symbolSize: 18,
                                        symbolShape: 'circle',
                                        symbolBorderColor: '#000',
                                        symbolBorderWidth: 2,
                                        onMouseEnter: (dataRef, event) => {
                                            const targetElement = event.target;
                                            const targetPosition = targetElement.getBoundingClientRect();

                                            let highlightedData = data.filter(dataRow => { return dataRow.id === dataRef.id; });


                                            setTooltip({
                                                display: 'block',
                                                top: targetPosition.top,
                                                left: targetPosition.left - 135,
                                                text: `${highlightedData[0].value} - ${(highlightedData[0].value / TotalVar * 100).toFixed(2)}%`
                                            });

                                            setHighlightedId(dataRef.id);

                                        },
                                        onMouseLeave: (dataRef, event) => {
                                            setTooltip({ ...tooltip, display: 'none' });
                                            setHighlightedId(null);
                                        },

                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                                    itemTextColor: 'rgba(225,0,0,1)',
                                                    symbolShape: 'square',
                                                    symbolBorderWidth: 2,
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ] : []}

                                defs={[
                                    linearGradientDef('gradient', [
                                        { offset: 0, color: 'inherit' },
                                        { offset: 100, color: 'inherit', opacity: 0.55 },
                                    ],
                                        { gradientTransform: 'rotate(45 0.5 0.5)' }),
                                ]}
                                fill={[{ match: '*', id: 'gradient' }]}

                                layers={[({ centerX, centerY, radius }) => {

                                    return (
                                        <g>
                                            <defs>

                                                <radialGradient id="radInnerGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                                                    <stop offset="35%" style={{ stopColor: "rgb(28, 28, 28)", stopOpacity: 0.8 }} />
                                                    <stop offset="90%" style={{ stopColor: "rgb(125,188,255)", stopOpacity: 0.83 }} />
                                                </radialGradient>
                                                <radialGradient id="radEdgeGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
                                                    <stop offset="95%" style={{ stopColor: "rgb(28, 28, 28)", stopOpacity: 0.8 }} />
                                                    <stop offset="100%" style={{ stopColor: "rgb(247, 247, 247)", stopOpacity: 1 }} />
                                                </radialGradient>
                                            </defs>

                                            <circle cx={centerX} cy={centerY} r={radius * (2.75 / remToPx(0.5))} fill="url(#radInnerGradient)" />
                                            <circle cx={centerX} cy={centerY} r={radius * (2.75 / remToPx(0.5))} fill="url(#radEdgeGradient)" />

                                        </g>
                                    );

                                },

                                    'arcs',


                                    'arcLabels',
                                    'arcLinkLabels', 'legends',
                                ({ centerX, centerY }) => {

                                    return (
                                        <>
                                            <text
                                                className='pieCenterPercent'
                                                x={centerX}
                                                y={centerY - remToPx(2)}
                                                textAnchor="middle"
                                                dominantBaseline="middle"
                                                style={{
                                                    fontSize: '18px',
                                                    fill: 'white',
                                                }}
                                            >
                                                {(index !== 2) ? null : `${(TotalVar / ListTotals[5] * 100).toFixed(1)}%`}
                                            </text>
                                            {(index === 2) ? <line x1={centerX - remToPx(28 / 16)} y1={centerY - remToPx(24 / 16)} x2={centerX + remToPx(28 / 16)} y2={centerY - remToPx(24 / 16)}
                                                style={{ stroke: 'rgb(125,188,255)', strokeWidth: '1', strokeOpacity: 1 }} />
                                                : null}


                                            <text
                                                className='pieCenterText'
                                                x={centerX}
                                                y={centerY}
                                                textAnchor="middle"
                                                dominantBaseline="middle"
                                                style={{
                                                    fontSize: '35px',
                                                    fill: 'white',
                                                    textShadow: '0px 0px 8px rgba(31, 128, 255, 0.88)',
                                                }}
                                            >
                                                {TotalVar}
                                            </text>



                                        </>
                                    );

                                }
                                ]} />) :
                            (<ResponsiveLine
                                animate
                                theme={NivoTheme}

                                data={data}
                                margin={{ top: remToPx(50 / 16), right: legendToggle ? remToPx(24) : remToPx(4), bottom: remToPx(90 / 16), left: remToPx(122 / 16) }}
                                xScale={{ type: 'point' }}
                                yScale={{
                                    type: 'linear',
                                    min: 0,
                                    max: 'auto',
                                    stacked: false,
                                    reverse: false
                                }}
                                curve="monotoneX"
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    format: (val) => `${xDateFormatter(val)}`,
                                    tickValues: data[0].data.map((d, i) => (i + 1) % (Math.floor((filterDateRange[1] - filterDateRange[0]) / xtickMax) + 1) === 0 ? d.x : null).filter(Boolean),
                                    tickSize: 16,
                                    tickPadding: 1,
                                    tickRotation: 0,
                                    legend: `${dateRangeTitles[0]} - ${dateRangeTitles[1]}`,
                                    legendOffset: 60,
                                    legendPosition: 'middle'
                                }}
                                axisLeft={{
                                    tickSize: 15,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: TextLanguage['Amount'][lang],
                                    legendOffset: -80,
                                    legendPosition: 'middle'
                                }}
                                enableGridX={false}
                                //gridXValues={monthStartDates}
                                colors={{ datum: 'color' }}
                                lineWidth={1}
                                enablePoints={false}
                                pointSize={1}
                                pointColor={{ theme: 'background' }}
                                pointBorderWidth={2}
                                pointBorderColor={{ from: 'serieColor' }}
                                pointLabelYOffset={-12}
                                enableSlices="x"
                                areaBlendMode='color-dodge'
                                sliceTooltip={({ slice }) => {
                                    const sliceindex = data[0].data.findIndex(d => d.x === slice.points[0].data.x);
                                    setvisibleBool(true);
                                    let totalType = 2;
                                    let filterTotal = false;
                                    switch (index) {
                                        case 1:
                                            totalType = 1;
                                            filterTotal = false;
                                            break;
                                        case 2:
                                            totalType = 0; //filter
                                            filterTotal = true;
                                            break;
                                        case 3:
                                            totalType = 2;
                                            filterTotal = false;
                                            break;
                                        case 4:
                                            totalType = ListTotals[3];
                                            filterTotal = true;
                                            break;
                                        case 5:
                                            totalType = ListTotals[4];
                                            filterTotal = true;
                                            break;
                                        case 6:
                                            totalType = ListTotals[5];
                                            filterTotal = true;
                                            break;
                                    };

                                    return (
                                        <div
                                            style={{
                                                display: visibleBool ? 'flex' : 'none',
                                                flexDirection: 'column',
                                                background: 'rgba(44, 44, 44, 0.9)',
                                                padding: '9px 12px',
                                                border: '2px solid rgba(12,55,222,0.1)',
                                                boxShadow: "-4px 4px 24px 0px black",
                                                borderRadius: 15
                                            }}
                                        >
                                            {
                                                // seriesCaseDaily[0] => Refused seriesCaseDaily[1] => Referred seriesCaseDaily[2] => Total
                                            }

                                            <div style={{ marginBottom: "12px", justifyContent: 'center', fontSize: 22 }}><strong>Day: {data[0].data[sliceindex].x}</strong></div>

                                            {filterTotal ? null
                                                : <div style={{ marginBottom: "12px", justifyContent: 'center', fontSize: 16, color: 'rgba(50, 243, 43, 0.86)' }}><strong>{TextLanguage["DailyTotal"][lang]} {seriesAll[0][0][totalType].data[sliceindex].y}</strong></div>}
                                            {slice.points.sort((a, b) => parseInt(b.data.yFormatted) - parseInt(a.data.yFormatted)).filter(point => { return (parseInt(point.data.yFormatted) !== 0); })
                                                .slice(0, 8)
                                                .map(point => (
                                                    <div
                                                        key={point.id}
                                                        style={{
                                                            color: point.serieColor,
                                                            padding: '3px 0',
                                                        }}
                                                    >
                                                        <strong>{point.serieId}</strong> <strong style={{ color: "white" }}>{parseInt(point.data.yFormatted)}</strong>
                                                    </div>
                                                ))}
                                        </div>
                                    );
                                }}
                                enableCrosshair={false}
                                legends={(legendToggle) ? [
                                    {
                                        anchor: 'top-right',
                                        direction: 'column',
                                        justify: false,
                                        translateX: 255,
                                        translateY: 0,
                                        itemsSpacing: 0,
                                        itemDirection: 'left-to-right',
                                        itemWidth: 222,
                                        itemHeight: 30,
                                        itemOpacity: 0.65,
                                        symbolSize: 18,
                                        symbolShape: 'circle',
                                        symbolBorderColor: '#000',
                                        symbolBorderWidth: 2,
                                        onMouseEnter: (dataRef, event) => {
                                            const targetElement = event.target;
                                            const targetPosition = targetElement.getBoundingClientRect();

                                            let highlightedData = data.filter(dataRow => { return dataRow.id === dataRef.id; });

                                            let tooltipText = (highlightedData[0].Sum || highlightedData[0].Sum === 0) ? `Total: ${highlightedData[0].Sum}
                                        - ${(highlightedData[0].Sum / TotalVar * 100).toFixed(2)}%` : `Total: ${ListTotals[1]} - 100%`;

                                            setTooltip({
                                                display: 'block',
                                                top: targetPosition.top,
                                                left: targetPosition.left - 205,
                                                text: tooltipText
                                            });

                                            setHighlightedId(dataRef.id);

                                        },
                                        onMouseLeave: (dataRef, event) => {
                                            setTooltip({ ...tooltip, display: 'none' });
                                            setHighlightedId(null);
                                        },

                                        effects: [
                                            {
                                                on: 'hover',
                                                style: {
                                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                                    itemTextColor: 'rgba(225,0,0,1)',
                                                    symbolShape: 'square',
                                                    symbolBorderWidth: 2,
                                                    itemOpacity: 1
                                                }
                                            }
                                        ]
                                    }
                                ] : []}

                                layers={[
                                    'grid',
                                    //'markers',
                                    //'areas',
                                    //'lines',
                                    //'crosshair',
                                    //'mesh',
                                    'points',
                                    'slices',
                                    // secondary charts custom grid layer
                                    ({ points, xScale, yScale }) => {

                                        const lines = points.reduce((acc, point) => {
                                            if (!acc[point.serieId]) {
                                                acc[point.serieId] = [];
                                            }
                                            acc[point.serieId].push(point);
                                            return acc;
                                        }, {});
                                        const chartHeight = yScale.range()[0] - yScale.range()[1];
                                        const chartWidth = xScale.range()[1] - xScale.range()[0];

                                        const list = Object.values(lines)[0].map((point, index) => {
                                            const day = point.data.x.split('.')[0];
                                            const dayInt = parseInt(day);

                                            if (index === (Object.values(lines)[0].length - 1)) {
                                            }
                                            return (((day === '01') || (index === 0 && dayInt < 25)) ? index : '');

                                        })
                                            .filter(String);

                                        const monthlyDistances = list.map((monthIndex, i) => {

                                            const day = Object.values(lines)[0][monthIndex].data.x.split('.')[0];
                                            const dayInt = parseInt(day);
                                            return (i !== list.length - 1) ? Object.values(lines)[0][list[i + 1]].x - Object.values(lines)[0][list[i]].x : (xScale.range()[1] - Object.values(lines)[0][list[i]].x);
                                        });


                                        //   let monthlyxGridDistance = 50;
                                        //   if (list.length === 1) { monthlyxGridDistance = Object.values(lines)[0][Object.values(lines)[0].length - 1].x - Object.values(lines)[0][list[0]].x }
                                        //   else { monthlyxGridDistance = (Object.values(lines)[0][list[1]].x - Object.values(lines)[0][list[0]].x) }
                                        //console.log(list);
                                        return (<g>
                                            {list.map((monthstartIndex, i) => {
                                                //Bug fix-if the range doesnt include the start of the month, set the line on the closest one
                                                const monthlyxGridDistance = monthlyDistances[i];
                                                return (
                                                    <g>

                                                        <path
                                                            style={{
                                                                transition: '0.5s ease-out 0s',
                                                                pointerEvents: 'none'
                                                            }}
                                                            key={`grid-line${monthstartIndex}`}
                                                            d={`M${Object.values(lines)[0][monthstartIndex].x},0 L${Object.values(lines)[0][monthstartIndex].x},${chartHeight}`}
                                                            fill="none"
                                                            stroke={'#fff'}
                                                            strokeOpacity={0.85}
                                                            strokeWidth={2} />
                                                        {((i === list.length - 1) && (xScale.range()[1] - Object.values(lines)[0][monthstartIndex].x) < monthlyxGridDistance / 2)

                                                            ?
                                                            null
                                                            :
                                                            <text
                                                                style={{
                                                                    fontSize: '1rem',
                                                                    fill: '#fff',
                                                                    textAnchor: "middle",
                                                                    transform: `translate(${Object.values(lines)[0][monthstartIndex].x + monthlyxGridDistance / 2}px,-10px)`
                                                                }}
                                                            >{TextLanguage[Object.values(lines)[0][monthstartIndex].data.x.split('.')[1]][lang]}</text>}

                                                        {(list.length === 1 && (monthlyxGridDistance / chartWidth < 0.65)) ?
                                                            <text
                                                                style={{
                                                                    fontSize: '1rem',
                                                                    fill: '#fff',
                                                                    textAnchor: "middle",
                                                                    transform: `translate(${Object.values(lines)[0][monthstartIndex].x - monthlyxGridDistance / 2}px,-10px)`
                                                                }}
                                                            >{TextLanguage[Object.values(lines)[0][monthstartIndex - 1].data.x.split('.')[1]][lang]}</text> : null}







                                                    </g>
                                                );
                                            })}
                                        </g>);
                                    },

                                    ({ points, xScale, yScale }) => {
                                        // Group points by id to create lines
                                        const lines = points.reduce((acc, point) => {
                                            if (!acc[point.serieId]) {
                                                acc[point.serieId] = [];
                                            }
                                            acc[point.serieId].push(point);
                                            return acc;
                                        }, {});


                                        return (<g>

                                            {Object.keys(lines).map(id => {
                                                const isHighlighted = highlightedId && highlightedId === (id);
                                                //var idSum = lines[id].map(point => {return point.y})
                                                //let sum = idSum.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
                                                return (

                                                    <>
                                                        <path
                                                            key={`${id}-line`}
                                                            d={lineGenerator(lines[id].map(point => ({ x: xScale(point.data.x), y: yScale(point.data.y) })))}
                                                            fill="none"
                                                            stroke={lines[id][0].serieColor}
                                                            //strokeOpacity={highlightedId ? (isHighlighted ? 1 : 0.15) : remap(sum,55000,45000,0,1)} Maybe reduce opacity for lower ranked hosps
                                                            strokeOpacity={highlightedId ? (isHighlighted ? 1 : 0.15) : 1}
                                                            strokeWidth={isHighlighted ? 5 : 3} />


                                                        <path
                                                            key={`${id}-area`}
                                                            d={areaGenerator(lines[id].map(point => ({ x: xScale(point.data.x), y: yScale(point.data.y) })))}
                                                            fill={lines[id][0].serieColor}
                                                            fillOpacity={highlightedId ? isHighlighted ? 0.25 : 0 : 0.018} />
                                                    </>

                                                );
                                            })}
                                        </g>);
                                    },
                                    'axes',
                                    'legends',
                                ]} />)}


                </div>


            </> : null
    );
}
