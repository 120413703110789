import customMarkerImageHotel from '../../img/markerhotel.png';


export function createHotelMarker(index) {
    const marker = document.createElement('div');
    marker.className = 'custom-marker-markhosp';
    marker.dataset.markerId = index;
    marker.style.backgroundImage = `url(${customMarkerImageHotel})`;
    marker.style.width = '64px';
    marker.style.height = '64px';
    marker.style.backgroundSize = 'cover';
    marker.style.backgroundPosition = '100% 50%';

    return marker;
}
