import React, { useEffect, useState } from "react";

import { Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";


export const CountdownDisplay = ({
    timer = 300,
    initialCount,
    setSubmitted,
    setVerificationSubmitted,
    setAwaitingVerification
}) => {
    const queryClient = useQueryClient();
    const [countdown, setCountdown] = useState(initialCount);

    useEffect(() => {
        if(timer - Math.floor((Date.now() - initialCount) / 1000) <= 1){
            setTimeout(() => {

                queryClient.removeQueries({ queryKey: ['login'] });
                queryClient.removeQueries({ queryKey: ['verify'] });
                setAwaitingVerification(false);
                setSubmitted(false);
                setVerificationSubmitted(false);
            }, 1000)

        }
        if (countdown > 0) {
            const timerId = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => clearTimeout(timerId);
        }

        // Clear the timer if the component is unmounted
    }, [countdown]);



    return (  ( timer - Math.floor((Date.now() - initialCount) / 1000) > 0 ) ?
    <Typography sx={{ color: 'white', fontSize: '2rem', fontWeight: 700, }}>
    {`${`${timer - Math.floor((Date.now() - initialCount) / 1000)}`}`}
    </Typography>:null);
}

