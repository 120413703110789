import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';



import OperatorApp from './pages/OperatorApp';
import DataDashboard from './pages/DataDashboard';
import LoginPage from './pages/LoginPage';
import { ThemeProvider, createTheme } from '@mui/material/styles';


import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { PrimeReactProvider } from 'primereact/api';
import DataEditor from './pages/DataEditor';


function AppRouter() {

    const theme = createTheme({ // Define Theme here
        palette: {
            text: {
                primary: 'rgb(255, 255, 255)',
            },
            gray: {
                tint: {
                    0: 'var(--color-gray-tint)',
                    1: 'var(--color-gray-tint1)',
                },
                0: 'var(--color-middle-gray)',

            },
            light: {
                0: 'var(--color-light0)',
                1: 'var(--color-light1)',
                2: 'var(--color-light2)',
                3: 'var(--color-light3)',
                4: 'var(--color-light-gray-tint)',
            },
            shadow: {
                0: 'var(--color-shadow0)',
                1: 'var(--color-shadow1)',
                2: 'var(--color-shadow2)',
                3: 'var(--color-shadow3)',
            },
            dark: {
                shadow: 'var(--color-dark-shadow)',
                0: 'var(--color-dark0)',
                1: 'var(--color-dark1)',
                2: 'var(--color-dark2)',
                3: 'var(--color-dark3)',
                4: 'var(--color-dark-gray-tint)',
                transparent: {
                    0: 'var(--color-dark0-transparent)',
                    1: 'var(--color-dark1-transparent)',
                    2: 'var(--color-dark2-transparent)',
                    3: 'var(--color-dark3-transparent)',
                }
            },
            gradient: {
                a: 'var(--background-gradient-a)',
                b: 'var(--background-gradient-b)',
                c: 'var(--background-gradient-c)',
                d: 'var(--background-gradient-d)',
                glow: 'var(--background-gradient-glow)',
                glowButton: 'var(--button-hover-glow-gradient)',

            },
            warningColor: {
                main: 'var(--color-warning)',
            },
            errorColor: {
                main: 'var(--color-error)',
                dark: 'var(--color-error-dark)',
            },
            successColor: {
                main: 'var(--color-success)',
            },
            mainColor: {
                default: 'var(--color-main-theme)',
                Light: 'var(--color-main-theme-light)',
                LightTransp: 'var(--color-main-theme-light-transparent)',
                DarkDesat: 'var(--color-main-theme-dark-desaturated)',
                DarkerDesat: 'var(--color-main-theme-darker-desaturated)',
                Transp: 'var(--color-main-theme-alpha)',
                Dark: 'var(--color-main-theme-dark)',
                DarkTransp: 'var(--color-main-theme-dark-transparent)',
                Darker: 'var(--color-main-theme-darker)',
                Darkest: 'var(--color-main-theme-darkest)',
                DarkestTransp: 'var(--color-main-theme-darkest-transparent)',
                Sat: 'var(--color-main-theme-saturated)',
            },
            typeColor: {
                Hospital: 'var(--theme-hospital-color)',
                Clinic: 'var(--theme-clinic-color)',
                MedCenter: 'var(--theme-medcenter-color)',
                Dentist: 'var(--theme-dentist-color)',
                Doctor: 'var(--theme-doctor-color)',
            },
            buttonGlow: {
                0: 'var(--button-hover-glow)',
                1: 'var(--button-hover-glow-transparent1)',
                2: 'var(--button-hover-glow-transparent2)',
                3: 'var(--button-hover-glow-transparent3)',
            }
        },
        typography: {
            fontFamily: 'Ariel, sans-serif', // Custom font family
            fontSize: 18, // Custom base font size

        },
        spacing: 8, // Custom spacing between components

        components: {
            MuiPaper: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'rgb(40, 50, 60)',
                        color: 'white',


                    },
                },
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'rgba(49,80,125,0.15)',
                        backdropFilter: 'blur(4px) brightness(0.8)',
                        transition: '0.45s ease-in-out 0s !important',
                    },
                },
            },
            MuiDateCalendar: {
                styleOverrides: {
                    root: {
                        color: 'white',
                        background: 'linear-gradient(to bottom, hsl(235, 7%, 32%) 0%, hsl(235, 7%, 46%) 60%)'
                    }
                },


            },
            MuiDayCalendar: {
                styleOverrides: {
                    monthContainer: {
                        background: 'linear-gradient(to bottom, hsl(235, 7%, 32%) 10%, hsl(235, 7%, 46%) 100%)',

                    },
                    header: {
                        background: 'linear-gradient(to top, rgba(22, 22, 22, 0.5) 0%, rgba(22, 22, 22, 0.4) 60%)',
                        borderBottom: '1px solid black',
                        borderTop: '1px solid black',
                    },
                    root: {
                        color: 'white',
                        background: 'linear-gradient(to bottom, hsl(235, 7%, 32%) 0%, hsl(235, 7%, 46%) 60%)'
                    }
                },
            },
            MuiTypography: {
                styleOverrides: {
                    root: {

                        alignItems: 'center',
                        display: 'flex',
                        fontFamily: 'Ariel, sans-serif', // Custom font family
                        fontSize: 18, // Custom base font size

                    },

                },

            },

            MuiTable: {
                styleOverrides: {
                    root: {
                        borderCollapse: 'collapse',
                        width: '100%',
                        // Add more styles here
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        border: '2px solid black',
                        padding: '0px',
                        width: '100%'
                        // Add more styles here
                    },
                },
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {


                        // Add more styles here
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {

                        // Add more styles here
                    },
                },
            },


            MuiInputLabel: {
                styleOverrides: {
                    root: { // apply the style to the root
                        color: 'white',
                        fontSize: '1.2rem',
                        '&.Mui-focused': { // change the color when focused
                            color: 'lightgreen',
                            fontSize: '1.2rem'
                        },
                    }
                }

            },
            MuiGrid: {
                styleOverrides: {
                    item: {
                        "&.HospListBox": {
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'scroll',
                            height: '75vh',
                            maxHeight: '75vh',
                            "@media screen and (min-width: 1200px)": {
                                maxWidth: 600,
                            },
                            "@media screen and (max-width: 1200px)": {
                                maxWidth: '100% !important',

                            },
                            "&::-webkit-scrollbar": {
                                width: '1px',
                            },
                        },
                        "&.left": {
                            backgroundColor: '#545657',
                            "@media screen and (max-width: 1200px)": {
                                maxWidth: '100% !important',
                            },
                        },
                    },
                },
            },
        }
    });










    const queryClient = new QueryClient(
        {
            defaultOptions: {

                queries: {

                    refetchOnWindowFocus: true,
                    refetchOnMount: true,
                    refetchOnReconnect: true,
                    retry: (failureCount, error) => {
                        return (failureCount < 4 && error.response?.status !== 401)
                     },
                    staleTime: 1000 * 60 * 10,
                    cacheTime: 1000 * 60 * 10,
                },

            },
            queryCache: new QueryCache({
                onError: async (error, _variables) => {
                    if (error.response?.status === 401 && !queryClient.isFetching({ queryKey: ['refresh'] })) {
                        if (_variables?.options?.queryKey && _variables.options.queryKey[0] !== 'refresh') {
                            await queryClient.invalidateQueries({ queryKey: ['refresh'] });
                            await queryClient.refetchQueries({ queryKey: _variables.options.queryKey });
                        }
                    } ;
                },
            }),
            mutationCache: new MutationCache({
                onError: async (error, variables, context, mutation) => {
                    if (error.response?.status === 401 && !queryClient.isFetching({ queryKey: ['refresh'] })) {
                        await queryClient.invalidateQueries({ queryKey: ['refresh'] });


                    };
                },
            })


        }
    );





    return (


        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <QueryClientProvider client={queryClient}>
                    <PrimeReactProvider value={{
                        pt: {
                            button: {
                                root: { className: 'bg-teal-500 hover:bg-teal-700 cursor-pointer text-white p-3 border-round border-none flex gap-2' },
                                label: 'text-white font-bold text-xl', // OR { className: 'text-white font-bold text-xl' }
                                icon: 'text-white text-2xl'
                            },
                            panel: {
                                header: 'bg-primary border-primary',
                                content: 'border-primary text-lg text-primary-700',
                                title: 'bg-primary text-xl',
                                toggler: 'bg-primary hover:bg-primary-reverse'
                            }
                        }
                    }}>
                        <ReactQueryDevtools initialIsOpen={true} />
                        <Routes>
                            <Route path="/" element={<LoginPage />} />
                        </Routes>
                        <Routes>
                            <Route path="/operator" element={<OperatorApp />} />
                        </Routes>
                        <Routes>
                            <Route path="/list" element={<DataEditor />} />
                        </Routes>
                        <Routes>
                            <Route path="/chart" element={<DataDashboard />} />
                        </Routes>
                    </PrimeReactProvider>
                </QueryClientProvider>
            </ThemeProvider>
        </BrowserRouter>


    );
}

export default AppRouter;