import {
    ToggleButtonGroup, Tooltip,
    Typography, Button
} from '@mui/material';
import LocalHospitalRoundedIcon from '@mui/icons-material/LocalHospitalRounded';
import LocalPharmacyOutlinedIcon from '@mui/icons-material/LocalPharmacyOutlined';
import TextLanguage from '../data/TextLanguage.json';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import DentistIcon from '../../img/DentistIcon';
import DescriptionOffIcon from '../../img/DescriptionOffIcon';
import MedCenterIcon from '../../img/MedCenterIcon';
import DoctorIcon from '../../img/DoctorIcon';
import DescriptionTwoToneIcon from '@mui/icons-material/DescriptionTwoTone';
import remToPx from '../../functions/remToPx';
import { CustomToggleButton } from '../StyledComponents/CustomToggleButton';
import {styled } from '@mui/material/styles';


const LargeFilterButton = styled(Button)(({ theme }) => ({

    color: 'white',
    backgroundColor: 'rgba(34, 34, 37, 0.9)',
    background: 'linear-gradient(to bottom, rgba(49,80,125,0.8), hsla(224, 93%, 16%, 0.43))',
    transitionDuration: '220ms',
    borderRadius: 0,

    transitionProperty: 'background',
    '& .Mui-disabled': {
        backgroundColor: `red`,
    },
    '&:hover, &.Mui-focusVisible': {
        transitionDuration: '1220ms',
        transitionProperty: 'background',
        backgroundColor: `rgba(125,188,255,0.02)`,
        background: 'linear-gradient(to bottom, rgba(52,84,129,0.8), rgba(66, 151, 255, 0.78))',

        boxShadow: 'inset 0px 0px 18px -2px rgb(125,188,255)',
        zIndex: 50,
    },

    '& .Mui-focused': {
        backgroundColor: `white`,
    },

}));




export const FilterButtonGroup = ({
    formats,setFormats,
    lang, filterByRegion, setfilterByRegion,
    expandAll,setexpandAll,
    regionalMode
}) => {


    const handleFormat = (event, newFormats) => {
        if (newFormats.length) { setFormats(newFormats); }
        //console.log(newFormats);
    };



    const handleFilterButton = (flip) => (event) => {
        flip = !flip;
        setfilterByRegion(flip);
    }


    const handleExpandButton = (flip) => (event) => {
        flip = !flip;
        setexpandAll(flip);
    }


    return <div className='toggleButGroup' style={{ backgroundColor: 'rgba(25,25,25,0.6)', }}>
        <ToggleButtonGroup
            sx={{ background: 'linear-gradient(180deg, rgb(49,80,125), rgba(34, 34, 37, 0.4))' }}
            value={formats}
            onChange={handleFormat}
            aria-label="text formatting"
        >
            <Tooltip title={TextLanguage["Hospital"][lang]} placement="top">
                <CustomToggleButton formats={formats} type='Hospital' value="Hospital" aria-label="Hospital">
                    <LocalHospitalRoundedIcon />
                </CustomToggleButton>
            </Tooltip>
            <Tooltip title={TextLanguage["Clinic"][lang]} placement="top">
                <CustomToggleButton formats={formats} type="Clinic" value="Clinic" aria-label="Clinic">
                    <LocalPharmacyOutlinedIcon />
                </CustomToggleButton>
            </Tooltip>
            <Tooltip title={TextLanguage["MedicalCenter"][lang]} placement="top">
                <CustomToggleButton formats={formats} type="Medical Center" value="MedCenter" aria-label="MedCenter">
                    <MedCenterIcon />
                </CustomToggleButton>
            </Tooltip>
            <Tooltip title={TextLanguage["Dentist"][lang]} placement="top">
                <CustomToggleButton formats={formats} type="Dentist" value="Dentist" aria-label="Dentist">
                    <DentistIcon />
                </CustomToggleButton>
            </Tooltip>
            <Tooltip title={TextLanguage["Doctor"][lang]} placement="top">
                <CustomToggleButton formats={formats} type="Doctor" value="Doctor" aria-label="Doctor">
                    <DoctorIcon />
                </CustomToggleButton>
            </Tooltip>
        </ToggleButtonGroup>
{ !regionalMode &&
        <LargeFilterButton variant="contained" sx={{ flexDirection: 'column-reverse', width: '100%' }}
            onClick={handleFilterButton(filterByRegion)}>

            {filterByRegion ? <FilterAltIcon sx={{ color: "white" }} /> : <FilterAltOffIcon sx={{ color: "DarkGray" }} />}

            <Typography sx={{ fontSize: remToPx(14 / 20), fontWeight: 600 }} variant='button'>{TextLanguage["Region"][lang]}</Typography>

        </LargeFilterButton>
        }

        <LargeFilterButton variant="contained" sx={{ flexDirection: 'column', color: 'DarkGray', width: '100%' }}
            onClick={handleExpandButton(expandAll)}>
            <Typography sx={{ fontSize: remToPx(14 / 20), fontWeight: 600, color: 'white' }} variant='button'>{TextLanguage["Notes"][lang]}</Typography>
            {expandAll ? <DescriptionTwoToneIcon sx={{ color: "white" }} /> : <DescriptionOffIcon /> // Custom Icon can't inherit color!!
            }

        </LargeFilterButton>
    </div>;
}
