
export const ModalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    borderRadius: 8,
    backdropFilter: "blur(6px)",
    WebkitBackdropFilter: "blur(6px)",
    transform: "translate(-50%, -50%)",
    minWidth: '33%',
    maxWidth: '95%',

    background: "var(--color-dark1-transparent)",
    border: "2px solid var(--color-gray-tint)",
    boxShadow: '-6px 10px 18px var(--color-dark0-transparent)',
    padding: 3,
};
