
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";

export const useUserListQuery = () => {


    async function fetchUserData() {

        return await axios.get(`${config.API_URL}/operator/userList`, {
            withCredentials: true,
        })

    }

    return useQuery(
        {
            queryKey: ['userData'],
            queryFn: fetchUserData,
            staleTime: 1000 * 60 * 60 * 6, // 6 hours
        }
    )
}