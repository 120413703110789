
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";
import { getCookie } from "../../../functions/getCookie.jsx";

export const useDashboardQuery = () => {

    async function fetchDashboardData() {
        const token = getCookie('accessToken');
        return await axios.get(`${config.API_URL}/data/dashboard`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })

    }
    return useQuery(
        {
            queryKey: ['dashboardData'],
            queryFn: fetchDashboardData,
            staleTime: 1000 * 60 * 60 * 24, // 6 hours
        }


    )

}