
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../config.js";

export const useVerificationQuery = ({loginInfo, verificationSubmitted}) => {


    async function verifyFn({ queryKey }) {
        const [_key, loginInfo] = queryKey;

            return await axios.post(`${config.API_URL}/login/verify`,
            loginInfo,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            });

    };

    return useQuery(
        {
            queryKey: ['verify',
                    {...loginInfo}],
            queryFn: verifyFn,
            enabled: !!verificationSubmitted && !!loginInfo && !!loginInfo?.username && !!loginInfo?.verificationCode,
            staleTime: Infinity,
            retry: false,
            gcTime: 5000
        });




}