export const createCustomMarkerImage = (color, size) => {
    // Specify the size of your marker image
    const canvas = document.createElement('canvas');
    canvas.width = size;
    canvas.height = size;
    const context = canvas.getContext('2d');

    // Draw a colored circle on the canvas
    context.beginPath();
    context.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI);
    context.fillStyle = color;
    context.fill();

    // Create a Mapbox GL JS image from the canvas
    return canvas.toDataURL('image/png');
};
