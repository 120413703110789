import { styled } from '@mui/material/styles';
import MuiAccordionSummary from '@mui/material/AccordionSummary';




export const AccordionSummary = styled((props) => (
    <MuiAccordionSummary

        {...props} />
))(({ theme, bgcolor1, bgcolor2, accordHeight }) => ({
    transitionProperty: 'width, height', transitionDuration: '200ms', transitionTimingFunction: 'ease-in-out',
    height: `${accordHeight}px`,
    flexDirection: 'row-reverse',

    background: `linear-gradient(90deg, ${bgcolor1}, ${bgcolor2})`,

    '&& .MuiAccordionSummary-content': {
        paddingLeft: 8,
        margin: 0
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(0),
    },
}));
