import { useState } from 'react';

import {
    Box, MenuItem, Button,
    Chip,
    Typography
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { FormField } from '../../StyledComponents/FormField';
import { ModalStyle } from '../../StyledComponents/ModalStyle';

import { useUpdateHospitalMutation } from '../../OperatorApp/hooks/useUpdateHospitalMutation';


export const RegionSelectorDialog = ({
    regionDataQuery,  cellEditorModalState, setCellEditorModalState,
    setsnackbarStatus}) => {

        const [addRegion, setAddRegion] = useState('');

        const hospitalMutation = useUpdateHospitalMutation({setsnackbarStatus, modalCellEditor: true, cellEditorModalState, setCellEditorModalState  })

        const handleRegionSelection = () => {
            if (addRegion === '') return;
            if (cellEditorModalState?.data?.value === null) {
                setCellEditorModalState({ open: true, type: 'Region', data: { ...cellEditorModalState?.data, value: [addRegion] } });
            } else {
                if (cellEditorModalState?.data?.value.includes(addRegion)) return;
                setCellEditorModalState({ open: true, type: 'Region', data: { ...cellEditorModalState?.data, value: [...cellEditorModalState?.data?.value, addRegion] } });
            }

        }

        const handleRegionRemove = (region) => () => {
            if (cellEditorModalState?.data?.value === null) return;
            const filteredRegions = cellEditorModalState?.data?.value?.filter((regionElement) => regionElement !== region);
            setCellEditorModalState({ open: true, type: 'Region', data: { ...cellEditorModalState?.data, value: filteredRegions } });

        }
        const handleSwitchRegionMain = (region) => () => {
            if (cellEditorModalState?.data?.value === null) return;
            const filteredRegions = cellEditorModalState?.data?.value?.filter((regionElement) => regionElement !== region);
            setCellEditorModalState({ open: true, type: 'Region', data: { ...cellEditorModalState?.data, value: [region, ...filteredRegions] } });

        }

        const handleRegionSelectionSubmit = () => {
            if (!cellEditorModalState?.data?.value) { return }

            const updaterData = {
                ...cellEditorModalState?.data?.rowData,
                Region: cellEditorModalState?.data?.value,
                Lng: parseFloat(cellEditorModalState?.data?.rowData?.Lng),
                Lat: parseFloat(cellEditorModalState?.data?.rowData?.Lat),
            }

            hospitalMutation.mutate({ updaterData: updaterData, oldRowData: cellEditorModalState?.data?.rowData });


        }





    return (<Box sx={[ModalStyle, { minWidth: '35%', minHeight: '35%', padding: '3.5rem', background: 'rgba(36, 36, 36, 0.9)' }]}>

        <div className='RegionSelectorContainerDialog'>
            <div style={{width:'100%',justifyContent:'center',display:'flex'}}>
                <Typography variant='h6' sx={{ color: 'white', width: '100%', textAlign: 'center'}}
                >
                    {`${cellEditorModalState?.data?.rowData.Name}`}
                </Typography>
            </div>
            <div className='RegionSelector'>
                <FormField
                    id="select-region"
                    select
                    value={addRegion}
                    onChange={(e) => setAddRegion(e.target.value)}
                    defaultValue={''}
                >
                    <MenuItem value={null}>None</MenuItem>
                    {regionDataQuery?.data?.data?.map((option) => (
                        <MenuItem key={`addregion-${option?.Region}`} value={option?.Region}>
                            {option?.Region}
                        </MenuItem>
                    ))}
                </FormField>
                <Button
                    onClick={handleRegionSelection}
                    sx={{ background: 'rgb(49,80,125)' }}>
                    <AddIcon sx={{ color: 'rgb(125,188,255)' }} />
                </Button>

            </div>
            {cellEditorModalState?.data && cellEditorModalState?.data?.value?.length > 0 &&
                <div className='RegionChipContainer'>

                    {cellEditorModalState?.data?.value?.map((region, index) => {
                        return <Chip
                            onClick={handleSwitchRegionMain(region)}
                            onDelete={handleRegionRemove(region)}
                            sx={{ background: index === 0 ? 'rgb(201, 99, 3)' : 'rgb(49,80,125)',
                            '&:hover': { background: index === 0 ? 'rgb(251, 131, 19)' : 'rgb(57, 93, 147)' },
                            color: 'white', width: 'min-content' }}
                            key={`regionElement-${region}`}
                            label={region} />;
                    })}

                </div>}


<div style={{width:'100%',justifyContent:'center',display:'flex'}}>

                    <Button sx={{
                    background: 'rgb(62, 63, 66)', '&:hover': { background: 'rgb(87, 87, 87)' },
                    color: 'white', width: '200px'
                }}
                    onClick={handleRegionSelectionSubmit}>
                    Submit
                </Button>
</div>
        </div>

    </Box>);
}
