
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../config.js";

export const useLoginQuery = ({loginInfo, submitted}) => {


    async function loginFn({ queryKey }) {
        const [_key, loginInfo] = queryKey;

            return await axios.post(`${config.API_URL}/login`,
            {...loginInfo, appName: 'OperatorApp' },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            });

    };

    return useQuery(
        {
            queryKey: ['login',
                    {...loginInfo}],
            queryFn: loginFn,
            enabled: !!submitted && !!loginInfo && !!loginInfo?.username && !!loginInfo?.password,
            staleTime: Infinity,
            retry: false,
            gcTime: 5000
        });




}