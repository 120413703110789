import { Popper } from '@mui/material/';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { styled } from '@mui/system';

export const StyledPopper = styled(Popper)(({ theme }) => ({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        background: 'transparent',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
}));
