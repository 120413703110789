
import { useReducer, useEffect, useRef, useState } from 'react';


import {
    Button, MenuItem, Typography, Chip, IconButton

} from '@mui/material';

import { FormField } from '../../StyledComponents/FormField';
import { useCreateHospitalMutation } from '../hooks/useCreateHospitalMutation';
import { useGeocodeQuery } from '../hooks/useGeocodeQuery';
import GeocodeMapComponent from './GeocodeMapComponent';
import { GeocodeResultsButtons } from './GeocodeResultsButtons';

import AddIcon from '@mui/icons-material/Add';
import { NewPatientButton } from '../../StyledComponents/NewPatientButton';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import TextLanguage from '../../data/TextLanguage.json';
import { GeocodeField } from './GeocodeField';

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD':
            return {
                ...state,
                [action.fieldName]: action.payload,
            };
        case 'REPLACE':
            return {
                ...action.tempval
            };
        case 'REMOVE_ARRAY_ITEM':
            if (!Array.isArray(state[action.fieldName])) {
                return state;
            }
            if (state[action.fieldName].length === 1) {
                return state;
            }
            return {
                ...state,
                [action.fieldName]: state[action.fieldName].filter((item) => item !== action.payload)
            };
        case 'PUSH_ARRAY_ITEM':
            if (!Array.isArray(state[action.fieldName])) {
                return state;
            }

            if (state[action.fieldName].includes(action.payload) || action.payload === null || action.payload === '') {
                return state;
            }
            return {
                ...state,
                [action.fieldName]: [...state[action.fieldName], action.payload]
            };
        case 'MOVE_ARRAY_ITEM':
            if (!Array.isArray(state[action.fieldName])) {
                return state;
            }
            if (state[action.fieldName][0] === action.payload) {
                return state;
            }
            return {
                ...state,
                [action.fieldName]: [action.payload, ...state[action.fieldName].filter((item) => item !== action.payload)]
            };

        default:
            return state;
    }
}

const initialState = {
    "Name": '',
    "Area": '',
    "Phone": '',
    "Fax": '',
    "Email": '',
    "Region": [],
    //"Region": [''],
    "Lat": 36.8685887,
    "Lng": 30.6484849,
    "MedGroup": null,
    "Type": 'Hospital'

};

const dragGeojson = {
    'type': 'FeatureCollection',
    'features': [
        {
            'type': 'Feature',
            'geometry': {
                'type': 'Point',
                'coordinates': [0, 0] // center of the map on init
            }
        }
    ]
};

const hospTypes = ['Hospital', 'Clinic', 'MedCenter', 'Dentist', 'Clinic/Dentist', 'Doctor'];



export const HospitalCreateForm = ({ setsnackbarStatus, regionDataQuery, setModalOpen, mapboxAPIKey, lang }) => {

    const map = useRef(null);

    const hospitalCreateMutation = useCreateHospitalMutation({ setsnackbarStatus, setModalOpen });

    const [hospitalUpdater, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (fieldName) => (e) => {

        dispatch({
            type: 'SET_FIELD',
            fieldName: fieldName,
            payload: e.target.value,
        });

    };
    const [addRegion, setAddRegion] = useState('');



    const handlePasteClipboard = () => {
        navigator.clipboard.readText().then(text => {
            const [lat, lng] = text?.split(', ');
            if (text?.split(', ')?.length === 2 && !isNaN(parseFloat(lat)) && !isNaN(parseFloat(lng))) {
                dispatch({
                    type: 'SET_FIELD',
                    fieldName: 'Lat',
                    payload: parseFloat(lat),
                });
                dispatch({
                    type: 'SET_FIELD',
                    fieldName: 'Lng',
                    payload: parseFloat(lng),
                });

            } else {
                setsnackbarStatus({ open: true, message: 'Clipboard Content should be (lat, long)', severity: 'error' });
            }
        });
    }

    const handleRegionSelection = () => {
        dispatch({
            type: 'PUSH_ARRAY_ITEM',
            fieldName: 'Region',
            payload: addRegion,
        });
    }
    const handleRegionRemove = (region) => () => {
        dispatch({
            type: 'REMOVE_ARRAY_ITEM',
            fieldName: 'Region',
            payload: region,
        });
    }
    const handleSwitchRegionMain = (region) => () => {
        dispatch({
            type: 'MOVE_ARRAY_ITEM',
            fieldName: 'Region',
            payload: region,
        });

    }
    //const hotelUpdateMutation = useUpdateHotelMutation({ searchMode:true ,setSelectedValue, setFormModalOpen,setsnackbarStatus  });

    const [geocodeSearchInput, setGeocodeSearchInput] = useState('');
    const [debouncedSearchInput, setDebouncedSearchInput] = useState('');
    //const [debouncedName, setDebouncedName] = useState(hospitalUpdater.Name);
    const debouncedSearchRef = useRef(null);

    useEffect(() => {
        debouncedSearchRef.current && clearTimeout(debouncedSearchRef.current);

        debouncedSearchRef.current = setTimeout(() => {
            setDebouncedSearchInput(geocodeSearchInput);
        }, 400);
        return () => clearTimeout(debouncedSearchRef.current);

    }, [geocodeSearchInput]);


    const handleSubmitEdit = () => {
        hospitalCreateMutation.mutate({ updaterData: hospitalUpdater });
    }

    const [selectedGeocode, setSelectedGeocode] = useState(null);

    const geocodeQuery = useGeocodeQuery({ searchInput: debouncedSearchInput, region: hospitalUpdater?.Region[0], type: 'hospital' });
    const handleGeocodeSelection = (feature) => (e) => {


        dispatch({
            type: 'SET_FIELD',
            fieldName: 'Lat',
            payload: feature.center[1],
        });
        dispatch({
            type: 'SET_FIELD',
            fieldName: 'Lng',
            payload: feature.center[0],
        });


        setSelectedGeocode(feature);

    };
    const handleCenterButton = () => {

        const center = map?.current?.getCenter();
        dispatch({
            type: 'SET_FIELD',
            fieldName: 'Lat',
            payload: center.lat,
        });
        dispatch({
            type: 'SET_FIELD',
            fieldName: 'Lng',
            payload: center.lng,
        });


    }

    useEffect(() => {

        dragGeojson.features[0].geometry.coordinates = [hospitalUpdater.Lng, hospitalUpdater.Lat];
        map?.current?.getSource('dragpoint')?.setData(dragGeojson);

    }, [hospitalUpdater.Lng, hospitalUpdater.Lat])


    return <div className='HotelEditFormContainer'>

        <div style={{ display: 'flex', justifyContent: 'center', height: '100%', padding: '0px' }}>

            <div className='CreateEntryFormContainer' >

                <Typography variant='h6' sx={{ fontSize: '1.75rem', color: 'white', fontWeight: 'bolder', marginBottom: '0.75rem' }}>
                    {TextLanguage['NewHospitalEntry'][lang]}
                </Typography>


                <div className='form-row'>

                    <FormField
                        label={TextLanguage['HospitalName'][lang]}
                        value={hospitalUpdater.Name}
                        onChange={handleFieldChange('Name')} />
                    <FormField
                        label={TextLanguage['Phone'][lang]}
                        value={hospitalUpdater.Phone}
                        onChange={handleFieldChange('Phone')} />
                </div>


                <div className='form-row'>

                    <FormField
                        label={TextLanguage['Fax'][lang]}
                        value={hospitalUpdater.Fax}
                        onChange={handleFieldChange('Fax')} />
                    <FormField
                        label={TextLanguage['Email'][lang]}
                        value={hospitalUpdater.Email}
                        onChange={handleFieldChange('Email')} />
                </div>
                <div className='form-row'>

                    <FormField
                        label={TextLanguage['Notes'][lang]}
                        multiline
                        rows={2}
                        value={hospitalUpdater.Notes}
                        onChange={handleFieldChange('Notes')} />
                    <FormField
                        id="hosp-type-selector"
                        select
                        value={hospitalUpdater.Type}
                        onChange={handleFieldChange('Type')}
                        defaultValue={'Hospital'}
                        helperText={TextLanguage["SelectType"][lang]}
                    >
                        {hospTypes.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </FormField>
                </div>
                <div className='form-row'>

                    <FormField
                        label={TextLanguage['Latitude'][lang]}
                        value={hospitalUpdater.Lat}
                        onChange={handleFieldChange('Lat')} />
                    <FormField
                        label={TextLanguage['Longitude'][lang]}
                        value={hospitalUpdater.Lng}
                        onChange={handleFieldChange('Lng')} />
                    <Button onClick={handlePasteClipboard} sx={{
                        background: 'rgb(49,80,125)', padding: '0px', margin: '0px',
                        '&:hover': { background: 'rgb(53, 94, 156)' }
                    }}>
                        <ContentPasteIcon sx={{ color: 'white', fontSize: '2.4rem' }} />
                    </Button>
                </div>

                <div className='RegionSelectorContainer'>

                    <div className='RegionTopWrapper'>
                        <Typography variant='h6' sx={{ fontSize: '1.25rem', color: 'white', fontWeight: 'bolder' }}>
                            {TextLanguage['Region'][lang]}
                        </Typography>
                    </div>

                    <div className='RegionBottomWrapper'>
                        <div className='RegionSelector'>
                            <FormField
                                id="select-region"
                                select
                                value={addRegion}
                                onChange={(e) => setAddRegion(e.target.value)}
                                defaultValue={''}
                            >
                                <MenuItem value={null}>None</MenuItem>
                                {regionDataQuery?.data?.data?.map((option) => (
                                    <MenuItem key={`addregion-${option?.Region}`} value={option?.Region}>
                                        {option?.Region}
                                    </MenuItem>
                                ))}
                            </FormField>
                            <Button
                                onClick={handleRegionSelection}
                                sx={{ background: 'rgb(49,80,125)' }}>
                                <AddIcon sx={{ color: 'rgb(125,188,255)' }} />
                            </Button>
                        </div>
                        {hospitalUpdater?.Region && hospitalUpdater?.Region.length > 0 &&
                            <div className='RegionChipContainer'>
                                {
                                    hospitalUpdater?.Region?.map((region, index) => {
                                        return <Chip
                                            onClick={handleSwitchRegionMain(region)}
                                            onDelete={handleRegionRemove(region)}
                                            sx={{ background: index === 0 ? 'rgb(201, 99, 3)' : 'rgb(49,80,125)', color: 'white', width: 'min-content' }}
                                            key={`regionElement-${region}`}
                                            label={region} />
                                    })
                                }
                            </div>
                        }

                    </div>
                </div>


                <div style={{ marginTop: '40px', marginBottom: '0px', alignItems: 'center' }}>
                    <NewPatientButton sx={{ fontSize: '1rem', background: 'rgb(49,80,125)', color: 'white', m: '5px', padding: '5px 15px 5px 15px', width: '150px', fontWeight: 'bold' }}
                        onClick={handleSubmitEdit}>
                        Submit
                    </NewPatientButton>
                </div>

            </div>




            <div style={{ width: '100%', height: '100%', position: 'relative', borderLeft: '0.5rem solid rgb(66, 66, 66)' }}>
                {<div className='GeocodeSelectionContainer'>

                    <div style={{ padding: '16px', width: '100%', gap: '16px', display: 'flex', flexDirection: 'row' }}>
                        <GeocodeField
                            label="Search"
                            value={geocodeSearchInput}
                            onChange={(e) => setGeocodeSearchInput(e.target.value)}
                        />


                        <Button
                            onClick={handleCenterButton}
                            sx={{ padding: '0px', background: 'rgba(77, 77, 77, 0.5)' }}>
                            <AddLocationAltIcon sx={{
                                color: 'rgb(125,188,255)', fontSize: '2.4rem', margin: '0px', padding: '0px'
                            }} />

                        </Button>



                    </div>

                    <div style={{ maxHeight: '16rem', overflowY: 'auto', width: '100%' }}>
                        <GeocodeResultsButtons
                            geocodeQuery={geocodeQuery}
                            handleGeocodeSelection={handleGeocodeSelection}
                            selectedGeocode={selectedGeocode}
                        />
                    </div>
                </div>
                }

                <GeocodeMapComponent
                    geocodeQuery={geocodeQuery}
                    selectedGeocode={selectedGeocode}
                    mapboxAPIKey={mapboxAPIKey}
                    dispatch={dispatch}
                    map={map}

                />
            </div>

        </div>

    </div>;
}
