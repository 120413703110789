import TextLanguage from '../../data/TextLanguage.json';
import remToPx from '../../../functions/remToPx';
import { ResponsiveLine } from '@nivo/line';
import { NivoTheme } from '../NivoTheme';

export const TotalCaseLineChart = ({
    index, activeChart,
    seriesAll, xDateFormatter, filterDateRange,
    xtickMax, dateRangeTitles, lang
}) => {
    return <div
        style={{
            height: '100%',
            width: '66%',
            position: 'fixed',
            display: 'block',
            visibility: (index === activeChart) ? 'visible' : 'hidden',
            background: 'linear-gradient(270deg, rgba(34, 34, 37, 1) 0%, rgb(37, 46, 60) 100%)',
            paddingTop: `${remToPx(100 / 16)}px`,
            paddingBottom: `${remToPx(90 / 16)}px`,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>

        <ResponsiveLine
            animate={false}
            theme={NivoTheme}
            data={seriesAll[0][0]}
            margin={{ top: remToPx(50 / 16), right: remToPx(55 / 16), bottom: remToPx(145 / 16), left: remToPx(122 / 16) }}
            xScale={{ type: 'point', format: 'native' }}
            yScale={{
                type: 'linear',
                min: 0,
                max: 'auto',
                stacked: false,
                reverse: false
            }}

            enableSlices="x"
            curve="monotoneX"
            enablePoints={false}
            enableArea={true}
            areaBaselineValue={0}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                format: (val) => `${xDateFormatter(val)}`,
                tickValues: seriesAll[0][0][0].data.map((d, i) => (i + 1) % (Math.floor((filterDateRange[1] - filterDateRange[0]) / xtickMax) + 1) === 0 ? d.x : null).filter(Boolean),
                tickSize: 16,
                tickPadding: 1,
                tickRotation: 0,
                legend: `${dateRangeTitles[0]} - ${dateRangeTitles[1]}`,
                legendOffset: 60,
                legendPosition: 'middle'
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: TextLanguage['Amount'][lang],
                legendOffset: -60,
                legendPosition: 'middle'
            }}
            enableGridX={false}
            //gridXValues={monthStartDates}
            colors={{ datum: 'color' }}
            lineWidth={3}
            areaBlendMode='overlay'
            enableCrosshair={false}
            layers={[
                'axes',
                'grid',
                'markers',
                'areas',
                'lines',
                'points',
                // 1st chart grid layer
                ({ points, xScale, yScale }) => {

                    const lines = points.reduce((acc, point) => {
                        if (!acc[point.serieId]) {
                            acc[point.serieId] = [];
                        }
                        acc[point.serieId].push(point);
                        return acc;
                    }, {});
                    const chartHeight = yScale.range()[0] - yScale.range()[1];
                    const chartWidth = xScale.range()[1] - xScale.range()[0];


                    const list = Object.values(lines)[0]?.map((point, index) => {

                        const day = point.data.x.split('.')[0];
                        const dayInt = parseInt(day);

                        if (index === (Object.values(lines)[0].length - 1)) {
                        }
                        return (((day === '01') || (index === 0 && dayInt < 25)) ? index : '');

                    })
                        .filter(String);

                    const monthlyDistances = list?.map((monthIndex, i) => {

                        const day = Object.values(lines)[0][monthIndex].data.x.split('.')[0];
                        const dayInt = parseInt(day);
                        return (i !== list?.length - 1) ? Object.values(lines)[0][list[i + 1]].x - Object.values(lines)[0][list[i]].x : (xScale.range()[1] - Object.values(lines)[0][list[i]].x);
                    });


                    return (<g>

                        {list.map((monthstartIndex, i) => {

                            const monthlyxGridDistance = monthlyDistances[i];
                            return (
                                <g>

                                    <path
                                        style={{
                                            transition: '0.5s ease-out 0s',
                                            pointerEvents: 'none'
                                        }}
                                        key={`grid-line${monthstartIndex}`}
                                        d={`M${Object.values(lines)[0][monthstartIndex].x},0 L${Object.values(lines)[0][monthstartIndex].x},${chartHeight}`}
                                        fill="none"
                                        stroke={'#fff'}
                                        strokeOpacity={0.85}
                                        strokeWidth={2} />
                                    {(i === list.length - 1 && (xScale.range()[1] - Object.values(lines)[0][monthstartIndex].x) < monthlyxGridDistance / 2)

                                        ?
                                        null
                                        :
                                        <text
                                            style={{
                                                fontSize: '1rem',
                                                fill: '#fff',
                                                textAnchor: "middle",
                                                transform: `translate(${Object.values(lines)[0][monthstartIndex].x + monthlyxGridDistance / 2}px,-10px)`
                                            }}
                                        >{TextLanguage[Object.values(lines)[0][monthstartIndex].data.x.split('.')[1]][lang]}</text>}

                                    {(list.length === 1 && (monthlyxGridDistance / chartWidth < 0.65)) ?
                                        <text
                                            style={{
                                                fontSize: '1rem',
                                                fill: '#fff',
                                                textAnchor: "middle",
                                                transform: `translate(${Object.values(lines)[0][monthstartIndex].x - monthlyxGridDistance / 2}px,-10px)`
                                            }}
                                        >{TextLanguage[Object.values(lines)[0][monthstartIndex - 1].data.x.split('.')[1]][lang]}</text> : null}
                                </g>
                            );
                        })}
                    </g>);
                },
                'slices',
            ]} />
    </div>;
}
