

export function interpolateColor(value, minValue, maxValue, startColor, endColor) {
    const normalizedValue = (value - minValue) / (maxValue - minValue);
    const startHSL = startColor.match(/\d+/g).map(Number);
    const endHSL = endColor.match(/\d+/g).map(Number);
    const interpolatedHue = startHSL[0] + normalizedValue * (endHSL[0] - startHSL[0]);
    const interpolatedColor = `hsl(${interpolatedHue}, ${startHSL[1]}%, ${startHSL[2]}%)`;
    // format: hsl(hue, saturation, lightness)
    return interpolatedColor;
  }
