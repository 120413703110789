import { styled } from '@mui/material/styles';
import { ToggleButton } from '@mui/material';




export const CustomToggleButton = styled(ToggleButton)(({ theme, type, formats }) => ({

    background: `linear-gradient(to bottom, ${theme.palette.mainColor.DarkTransp}, ${theme.palette.mainColor.DarkestTransp}),radial-gradient(at center top, ${theme.palette.mainColor.Transp}, ${theme.palette.dark[1]})`,
    color: `${(() => {
        switch (type) {
            case 'Hospital':
                return formats.includes('Hospital') ? theme.palette.typeColor.Hospital : theme.palette.dark[1];
            case 'Clinic':
                return formats.includes('Clinic') ? theme.palette.typeColor.Clinic : theme.palette.dark[1];
            case 'Medical Center':
                return formats.includes('MedCenter') ? theme.palette.typeColor.MedCenter : theme.palette.dark[1];
            case 'Dentist':
                return formats.includes('Dentist') ? theme.palette.typeColor.Dentist : theme.palette.dark[1];
            case 'Doctor':
                return formats.includes('Doctor') ? theme.palette.typeColor.Doctor : theme.palette.dark[1];
        }
    })()}`,

    '&:hover, &.Mui-focusVisible': {
        transition: '0.5s ease all',
        boxShadow: `inset 0px 0px 12px -2px ${theme.palette.buttonGlow[1]}`,
        zIndex: 50,
    },
}));
