import customMarkerImageHospital from '../../img/markerhospital.png';
import customMarkerImageClinic from '../../img/markerClinic.png';
import customMarkerImageDentist from '../../img/markerDentist.png';
import customMarkerImageDoctor from '../../img/markerDoctor.png';
import customMarkerImageMedCenter from '../../img/markerMedCenter.png';

export function createHospitalMarker(index, type) {
    const marker = document.createElement('div');
    marker.className = 'custom-marker-markhosp';
    marker.dataset.markerId = index;
    marker.style.backgroundSize = 'cover';


    switch (type) {
        case 'Hospital':
            marker.style.backgroundImage = `url(${customMarkerImageHospital})`;
            marker.style.width = '48px';
            marker.style.height = '48px';
            marker.style.backgroundPosition = '50% 0%';
            break;
        case 'Clinic':
            marker.style.backgroundImage = `url(${customMarkerImageClinic})`;
            marker.style.width = '48px';
            marker.style.height = '48px';
            marker.style.backgroundPosition = '50% 0%';
            break;
        case 'Dentist':
            marker.style.backgroundImage = `url(${customMarkerImageDentist})`;
            marker.style.width = '36px';
            marker.style.height = '50px';
            marker.style.backgroundPosition = '50% 100%';
            break;
        case 'Doctor':
            marker.style.backgroundImage = `url(${customMarkerImageDoctor})`;
            marker.style.width = '42px';
            marker.style.height = '48px';
            marker.style.backgroundPosition = '50% 0%';
            break;
        case 'MedCenter':
            marker.style.backgroundImage = `url(${customMarkerImageMedCenter})`;
            marker.style.width = '48px';
            marker.style.height = '48px';
            marker.style.backgroundSize = '80%';
            marker.style.backgroundPosition = '50% 100%';
            marker.style.backgroundRepeat = 'no-repeat';
            break;
        default:
            break;

    }




    return marker;
}
