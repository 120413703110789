
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

export const useCreatePatientMutation = ({setFormModalOpen}) => {

    return useMutation({
        mutationFn: ({ patientForm }) => {


            return axios.post(
                `${config.API_URL}/data/createPatient`,
                patientForm,
                {
                    withCredentials: true,
                }
            );
        },
        onSuccess: (data, variables) => {
            setFormModalOpen(false);
        }
    });
}
