
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";

export const useHospitalQuery = () => {


    async function fetchHospitalData() {

        return await axios.get(`${config.API_URL}/operator/hospitalList`, {
            withCredentials: true,
        })

    }

    return useQuery(
        {
            queryKey: ['hospitalData'],
            queryFn: fetchHospitalData,
            staleTime: 1000 * 60 * 60 * 6, // 6 hours
        }
    )
}