import { Snackbar } from '@mui/material';
import React from 'react';

import { styled } from '@mui/material/styles';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = styled(MuiAlert)(({ theme }) => ({
    background: theme.palette.gray.tint[0],
    color: theme.palette.text.primary,
    '& .MuiAlert-icon': {
        fontSize: '1.5rem',
        marginRight: '0.5rem',

    },
    '&.MuiPaper-root': {
        outline: `2px solid ${theme.palette.gray[0]}`,
        boxShadow: `0px 0px 16px 4px ${theme.palette.dark.shadow}, inset 0px 0px 12px -4px ${theme.palette.dark.shadow}`,
    },

    '& .MuiAlert-message': {
        fontSize: '1rem',
        fontWeight: 'bolder',
        color: 'white'
    },

    '&.MuiAlert-filledSuccess': {
        backgroundColor: 'green',
        color: theme.palette.successColor.main,
        outline:'none',
        borderLeft: `4px solid ${theme.palette.successColor.main}`,

    },
    '&.MuiAlert-filledError': {
        backgroundColor: theme.palette.errorColor.dark,
        color: theme.palette.errorColor.main,
        outline:'none',
        borderLeft: `4px solid ${theme.palette.errorColor.main}`,


    },
    '&.MuiAlert-filledWarning': {
        backgroundColor: theme.palette.warningColor.main,
        color: theme.palette.warningColor.main,
        outline:'none',
        borderLeft: `4px solid ${theme.palette.warningColor.main}`,
    },
    '&.MuiAlert-root': {

        paddingBlock:'0.75rem',
        borderRadius: '8px 8px 0px 0px',
        backgroundColor: theme.palette.gray.tint[1],
        backdropFilter: 'blur(6px)',
        marginBottom:'-16px',

    },



}));


export const CustomSnackbar = ({snackbarStatus, setsnackbarStatus}) => {
    return <Snackbar open={snackbarStatus?.open} autoHideDuration={2500} onClose={(event, reason) => {
        if (reason === 'clickaway') { return; };
        setsnackbarStatus({ ...snackbarStatus, open: false });
    }}>
        <Alert variant='filled' onClose={(event, reason) => { if (reason === 'clickaway') { return; }; setsnackbarStatus({ ...snackbarStatus, open: false }); }} severity={snackbarStatus?.severity} sx={{ width: '100%' }}>
            {snackbarStatus?.message}
        </Alert>
    </Snackbar>;
}
