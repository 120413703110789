
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';
import { useNavigate } from 'react-router-dom';

export const useLogoutMutation = () => {
    const navigate = useNavigate();

    return useMutation({


        mutationFn: () => {

            return axios.post(
                `${config.API_URL}/login/logout`,
                {},
                { withCredentials: true }
            );
        },
        onSettled: (data, error, variables, context) => {
               navigate('/');
        },


    })






}