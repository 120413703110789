
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config';

export const useRemoveHotelMutation = ({setModalOpen, setsnackbarStatus}) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ updaterData }) => { // hotelInfo: {Name: ..., _id: ...}

            return axios.delete(`${config.API_URL}/operator/removeHotel`, updaterData, {
                withCredentials: true,
            });
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['hotelData'] });
            setModalOpen(false);
        },
        onError: (error, variables) => {
            setsnackbarStatus({ open: true, message: 'There was an error during the update', severity: 'error' });

        }
    });
}
