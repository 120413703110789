
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";
import { getCookie } from "../../../functions/getCookie.jsx";

export const useHotelQuery = () => {

    async function fetchHotelData() {

        return await axios.get(`${config.API_URL}/operator/hotelList`, {
            withCredentials: true,
        })

    }
    return useQuery(
        {
            queryKey: ['hotelData'],
            queryFn: fetchHotelData,
            staleTime: 1000 * 60 * 60 * 6, // 6 hours
        }




    )

}