
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../config.js";



export const useRefreshQuery = () => {



    async function refreshFn() {


            return await axios.get(`${config.API_URL}/login/refresh`, {
                withCredentials: true
            });


    };

    return useQuery(
        {
            queryKey: ['refresh'],
            queryFn: refreshFn,
            staleTime: 1000 * 60 * 15,
            refetchInterval: 1000 * 60 * 60,

        });




}