




import { useState, useEffect, useRef } from 'react';


import TextLanguage from '../components/data/TextLanguage.json';

import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartRoundedIcon from '@mui/icons-material/PieChartRounded';
import StackedLineChartRoundedIcon from '@mui/icons-material/StackedLineChartRounded';
import remToPx from '../functions/remToPx'
import useLocalStorage from '../hooks/useLocalStorage';

import { line, curveMonotoneX, area } from 'd3-shape';
import { rgb } from 'd3-color';
import { scaleSequential } from 'd3-scale';
import { interpolateWarm, interpolateCividis, interpolatePlasma, interpolatePiYG ,interpolateCool,interpolateBlues } from 'd3-scale-chromatic';

import MenuIcon from '@mui/icons-material/Menu';

import { Box, Button } from '@mui/material';

import Typography from '@mui/material/Typography';
import { DateSlider } from '../components/DataDashboard/DateSlider';
import { useDashboardQuery } from '../components/DataDashboard/hooks/useDashboardQuery';
import { useValidateQuery } from '../hooks/useValidateQuery';

import { LanguageSelector } from '../components/LanguageSelector';
import { PageMenu } from '../components/PageMenu'
import { NivoChartDataGenerator } from '../components/DataDashboard/functions/NivoChartDataGenerator';
import { TotalCaseLineChart } from '../components/DataDashboard/components/TotalCaseLineChart';
import { TotalCasePieChart } from '../components/DataDashboard/components/TotalCasePieChart';
import { BreakdownCaseNivoChart } from '../components/DataDashboard/components/BreakdownCaseNivoChart';

import { xDateFormatter } from '../components/DataDashboard/functions/xDateFormatter';
import { useRefreshQuery } from '../hooks/useRefreshQuery';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';


function DataDashboard() {

    const currentPage = "DataDisplay";

    const queryClient = useQueryClient();

    const validateQuery = useValidateQuery();
    const refreshQuery = useRefreshQuery();
    const navigate = useNavigate();

    useEffect(() => {

        if (refreshQuery.isError) {
            console.log('Refresh Token Expired');
            navigate('/');
        } else if (refreshQuery.isSuccess) {
          queryClient.refetchQueries({ type: 'active', stale: true });
        }

    }, [refreshQuery.isSuccess, refreshQuery.isError])

/*
    let HospitalDailyListFull = [];
    let RegionDailyListFull = [];
    let TravelCompanyDailyListFull = [];
    let InsuranceDailyListFull = [];
    let OperatorDailyListFull = [];
    let RefusalDailyListFull = [];
    let CasesDailyListFull = [];
*/

    const [dateList, setdateList] = useState([])
    const dateListFirst = useRef();

    const dashboardDataQuery = useDashboardQuery();

    useEffect(() => {
        if(dashboardDataQuery.isSuccess){

            const receivedData = {};

            dashboardDataQuery?.data?.data.forEach((doc) => {

                switch (doc.name) {
                    case "HospitalDailyList":
                        receivedData.HospitalDailyListFull = doc.HospitalDailyList;
                        break;
                    case "RegionDailyList":
                        receivedData.RegionDailyListFull = doc.RegionDailyList;
                        break;
                    case "TravelCompanyDailyList":
                        receivedData.TravelCompanyDailyListFull = doc.TravelCompanyDailyList;
                        break;
                    case "InsuranceDailyList":
                        receivedData.InsuranceDailyListFull = doc.InsuranceDailyList;
                        dateListFirst.current = Object.keys(receivedData.InsuranceDailyListFull[0]).filter((key) => { return (key !== 'Name' && key !== "Sum" && key !== '_id') });
                        setdateList(Object.keys(receivedData.InsuranceDailyListFull[0]).filter((key) => { return (key !== 'Name' && key !== "Sum" && key !== '_id') }));
                        break;
                    case "OperatorDailyList":
                        receivedData.OperatorDailyListFull = doc.OperatorDailyList;
                        break;
                    case "RefusalDailyList":
                        receivedData.RefusalDailyListFull = doc.RefusalDailyList;
                        break;
                    case "DailyCaseList":
                        receivedData.CasesDailyListFull = doc.DailyCaseList;
                        break;
                }
            });


        setfilterDateRange([dateListFirst.current?.length - 31, dateListFirst.current?.length - 1])
        setretrievedData(receivedData);


        }


      }, [dashboardDataQuery.data,dashboardDataQuery.isSuccess,dashboardDataQuery.isError])


    // ================= Filter By Date ======================


    //let monthStartDates = dateList.map((date, index) => (['08','16','24'].includes(date.split('.')[0])) ? index : '').filter(String);
    //monthStartDates = monthStartDates.map((dateIndex) => {return dateList[dateIndex]});

    const [filterDateRange, setfilterDateRange] = useState([0, 1]);
    const minDateRangeDistance = 30;
    const xtickMax = 40 / 2560 * window.innerWidth; // scale by width!!!





    const handleDateSliderChange = (event, newValue, activeThumb) => {

        if (!Array.isArray(newValue)) {
            return;
        }
        //  let centerValue = (filterDateRange[1] + filterDateRange[0]) / 2;
        //  let valueRange = filterDateRange[1] - filterDateRange[0];

        if (newValue[1] - newValue[0] < minDateRangeDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], (dateList.length - 1) - minDateRangeDistance);
                setfilterDateRange([Math.max(0, clamped), Math.min(clamped + minDateRangeDistance, (dateList.length - 1))]);
            } else {
                const clamped = Math.max(newValue[1], minDateRangeDistance);
                setfilterDateRange([Math.max(0, clamped - minDateRangeDistance), Math.min(clamped, (dateList.length - 1))]);
            }
        } else { setfilterDateRange(newValue) }

    }





    const valueLabelFormat = (value) => {
        return dateList[value] // Format date as "YYYY-MM-DD"
    };







    // Date.parse expects 'YYYY-MM-DDTHH:mm:ss.sssZ' or simply 'YYYY-MM-DD'

    //const [filterStartDate, setfilterStartDate] = useState('07.07.2023') // set default to current date - 1 month?
    //const [filterEndDate, setfilterEndDate] = useState('26.07.2023')

    function dateFormatter(date) { // convert DD.MM.YYYY to YYYY-MM-DD
        if (date == null) { return "2023-01-11" }
        let dateComponents = date.split(".");
        let formattedDate = dateComponents.reverse().join("-");
        return formattedDate;
    }


    /* chart order
    0 Hosp
    1 ref
    2 reg
    3 TC
    4 IC
    5 Op
    */

    function filterDates(jsonObject, startDate, endDate) {
        // Parse the start and end dates
            let start = Date.parse(dateFormatter(startDate));
            let end = Date.parse(dateFormatter(endDate));

            // Iterate over the keys of the JSON object
            jsonObject = jsonObject.map((record) => {
                //let firstColumnName = Object.keys(record)[0]

                // Resulting object
                let acc = 0;
                let result = {};
                result["Name"] = record["Name"];
                for (let key in record) {


                    if (key !== 'Name' && key !== 'Sum') {
                        // Parse the key as a date (key comes in as DD.MM.YYYY) to (YYYY-MM-DD)

                        let dateComponents = key.split(".");
                        let formattedDate = dateComponents.reverse().join("-");

                        let date = Date.parse(formattedDate);

                        // If the key is a date within the range, add it to the result
                        if (!isNaN(date) && start <= date && date <= end) {

                            acc += record[key]

                            result[key] = record[key];

                        }
                    }

                }

                result["Sum"] = acc;

                return result;

            }
            )


            let totalSum = jsonObject.reduce((total, item) => total + item.Sum, 0);

            // Update the state using a function to ensure we're working with the most recent state


            return [ jsonObject, totalSum];

    }


    const filterDatesCases = (jsonObject, startDate, endDate) => {   // Diff function for CasesDailyList (structure is {Date: -- , yTotal: -- , yReferred: --})
        // Parse the start and end dates
        let start = Date.parse(dateFormatter(startDate));
        let end = Date.parse(dateFormatter(endDate));

        // Iterate over the keys of the JSON object
        jsonObject = jsonObject.filter((record) => {

            // Parse the key as a date (key comes in as DD.MM.YYYY) to (YYYY-MM-DD)

            let date = Date.parse(dateFormatter(record["Date"]));

            // If the key is a date within the range, add it to the result
            if (!isNaN(date) && start <= date && date <= end) { return true } else { return false }

        })
        let dateTitleStartEnd = [] // Pick out the first and last time for the title range at the bottom like 01.01.2023-12.05.2023
        dateTitleStartEnd[0] = jsonObject[0]["Date"]
        dateTitleStartEnd[1] = jsonObject[jsonObject.length - 1]["Date"]
        return [jsonObject, dateTitleStartEnd];

    }


/*
    function remap(value, from1, to1, from2, to2) {
        return (value - from1) / (to1 - from1) * (to2 - from2) + from2;
    }
*/

    // ============ LanguageSelector ==============



    const [lang, setlang] = useLocalStorage('lang', 'enUS');

    let TitleArray = [
        TextLanguage["Total Case Data"],
        TextLanguage["Hospital Data"],
        TextLanguage["Refusal Data"],
        TextLanguage["Regional Data"],
        TextLanguage["Travel Company Data"],
        TextLanguage["Insurance Company Data"],
        TextLanguage["Operator Data"],

    ];


    // ============== LanguageSelector End ===============
    const drawerSize = 70;
    const [activeChart, setActiveChart] = useState(0);
    const [scrollPos, setscrollPos] = useState(0);
    const [minimapReveal, setminimapReveal] = useState(false);
    let scrollAcc = 0;
    const minimaptimeoutRef = useRef(null);
    const pieAngleTimoutRef = useRef(null);
    const [TotalCaseBarPos, setTotalCaseBarPos] = useState(0);
    const [visibleBool, setvisibleBool] = useState(true); // slice tooltip bugfix (turn vis:hidden on chart change)
    const [pieAngle, setpieAngle] = useState(359);

    const [retrievedData, setretrievedData] = useState(false);
    const [dataProcessed, setdataProcessed] = useState(false);

    const handleScroll = (e) => {
        let scrollAccHold;
        const direction = e.deltaY > 0 ? 1 : -1;



        scrollAccHold = scrollAcc;
        scrollAcc += e.deltaY;
        scrollAcc = Math.max(Math.min(scrollAcc, 600), 0);

        if (scrollAccHold !== scrollAcc) {
            clearTimeout(minimaptimeoutRef.current);
            // setminimapReveal(true); MINIMAP DISABLED!!!!!
        }

        minimaptimeoutRef.current = setTimeout(() => {
            setminimapReveal(false);
        }, 2000);


        setscrollPos(scrollAcc * 1.5);


        setActiveChart((prevChart) => {
            const nextChart = prevChart + direction;
            if (nextChart < 0) { return 0 }; // prevent going below the first chart
            if (nextChart === 0) { setpieAngle(45); setTotalCaseBarPos(0); } else {
                setTimeout(() => {
                    setpieAngle(0);
                }, 25);


                setTotalCaseBarPos(drawerSize);
            }
            if (nextChart >= 7) return 7 - 1; // prevent going above the last chart


            setvisibleBool(false); // ????? why doesn't this run
            //console.log(visibleBool);

            setTooltip({ ...tooltip, display: 'none' });
            setHighlightedId(null);


            return nextChart;
        });
    };



    let HospitalDailyList = [];
    let RegionDailyList = [];
    let TravelCompanyDailyList = [];
    let InsuranceDailyList = [];
    let OperatorDailyList = [];
    let RefusalDailyList = [];
    let CasesDailyList = [];

    const [dateRangeTitles,setdateRangeTitles] = useState(['','']);


    //  let HospListTotal = 0;               0
    //  let RegionListTotal = 0;             1
    //  let TCListTotal = 0;                 2
    //  let ICListTotal = 0;                 3
    //  let RefusalListTotal = 0;            4
    //  let OperatorListTotal = 0;           5
    //

    const [ListTotals, setListTotals] = useState([0, 0, 0, 0, 0, 0])


    let seriesCaseDaily = [];
    let seriesCaseSum = [];
    let seriesHospDaily = [];
    let seriesHospSum = [];
    let seriesRefusalDaily = [];
    let seriesRefusalSum = [];
    let seriesRegionDaily = [];
    let seriesRegionSum = [];
    let seriesTCDaily = [];
    let seriesTCSum = [];
    let seriesInsCDaily = [];
    let seriesInsCSum = [];
    let seriesOperatorDaily = [];
    let seriesOperatorSum = [];

    const [seriesAll, setseriesAll] = useState([[]]);


    useEffect(() => {

        window.addEventListener('wheel', handleScroll);
        return () => {
            window.removeEventListener('wheel', handleScroll);
        };

    }, []);

    let hospTotal = 0;
    let RefTotal = 0;
    let regionTotal = 0;
    let TCTotal = 0;
    let ICTotal = 0;
    let OpTotal = 0;


    useEffect(() => {




        if (!retrievedData) { return }

        ///[RefusalDailyList, RefTotal]


        const totalCaseDaily = filterDatesCases(retrievedData.CasesDailyListFull, dateList[filterDateRange[0]], dateList[filterDateRange[1]]);

           const hp = filterDates(retrievedData.HospitalDailyListFull, dateList[filterDateRange[0]], dateList[filterDateRange[1]])
       const ref = filterDates(retrievedData.RefusalDailyListFull, dateList[filterDateRange[0]], dateList[filterDateRange[1]])
       const reg     = filterDates(retrievedData.RegionDailyListFull, dateList[filterDateRange[0]], dateList[filterDateRange[1]])
       const tc     = filterDates(retrievedData.TravelCompanyDailyListFull, dateList[filterDateRange[0]], dateList[filterDateRange[1]])
       const  ic    = filterDates(retrievedData.InsuranceDailyListFull, dateList[filterDateRange[0]], dateList[filterDateRange[1]])
       const  op    = filterDates(retrievedData.OperatorDailyListFull, dateList[filterDateRange[0]], dateList[filterDateRange[1]])

       CasesDailyList = totalCaseDaily[0]
       setdateRangeTitles(totalCaseDaily[1])
       HospitalDailyList = hp[0]
        hospTotal = hp[1]
        RefusalDailyList = ref[0]
        RefTotal = ref[1]

        RegionDailyList = reg[0]
        regionTotal = reg[1]
        TravelCompanyDailyList = tc[0]
        TCTotal = tc[1]
        InsuranceDailyList = ic[0]
        ICTotal = ic[1]
        OperatorDailyList = op[0]
        OpTotal = op[1]

        let totaltest = [hospTotal, RefTotal, regionTotal, TCTotal, ICTotal, OpTotal];

        setListTotals(totaltest)

        seriesCaseDaily =
            [
                {
                    id: TextLanguage["RefusedTotal"][lang], color: '#e00000b3', data: CasesDailyList.map(record => {
                        var day = record["Date"].split('.')[0]
                        if (day[0] === '0') { day = day.slice(1) }
                        //return { x: day, y: (record['yTotal'] - record['yReferred']) }
                        return { x: record["Date"], y: (record['yTotal'] - record['yReferred']) }
                    })
                },
                {
                    id: TextLanguage["ReferredTotal"][lang], color: '#20c11ab3', data: CasesDailyList.map(record => {
                        var day = record["Date"].split('.')[0]
                        if (day[0] === '0') { day = day.slice(1) }
                        //return { x: day, y: record['yReferred'] }
                        return { x: record["Date"], y: record['yReferred'] }
                    })
                },
                {
                    id: TextLanguage["CasesTotal"][lang], color: '#ffffff', data: CasesDailyList.map(record => {
                        var day = record["Date"].split('.')[0]
                        if (day[0] === '0') { day = day.slice(1) }
                        //return { x: day, y: record['yTotal'] }
                        return { x: record["Date"], y: record['yTotal'] }
                    })
                }
            ];

        seriesCaseSum =
            [{
                "id": TextLanguage["Refused"][lang],
                "label": "Refused Cases",
                "value": totaltest[1], // RefusedListTotal
                "color": "#e00000b3"
            },
            {
                "id": TextLanguage["Referred"][lang],
                "label": "Referred Cases",
                "value": totaltest[0], // HospListTotal
                "color": "#20c11ab3"
            }];


        [seriesHospDaily, seriesHospSum] = generateNivoChartData(HospitalDailyList.sort((a, b) => a.Sum - b.Sum).slice(HospitalDailyList.length - 16, HospitalDailyList.length)); // HospitalDailyList.length - 25 figure out a way to slice these
        [seriesRefusalDaily, seriesRefusalSum] = generateNivoChartData(RefusalDailyList.sort((a, b) => a.Sum - b.Sum));
        [seriesRegionDaily, seriesRegionSum] = generateNivoChartData(RegionDailyList.sort((a, b) => a.Sum - b.Sum));
        [seriesTCDaily, seriesTCSum] = generateNivoChartData(TravelCompanyDailyList.sort((a, b) => a.Sum - b.Sum));
        [seriesInsCDaily, seriesInsCSum] = generateNivoChartData(InsuranceDailyList.sort((a, b) => a.Sum - b.Sum));
        [seriesOperatorDaily, seriesOperatorSum] = generateNivoChartData(OperatorDailyList.sort((a, b) => a.Sum - b.Sum));


        seriesOperatorDaily.push({
            id: 'Total Cases', color: '#ffffff', Sum: totaltest[5], data: CasesDailyList.map(record => {
                var day = record["Date"].split('.')[0]
                if (day[0] === '0') { day = day.slice(1) }
                return { x: record["Date"], y: record['yTotal'] }

            })
        });

        seriesTCDaily.push({
            id: 'Total Cases', color: '#ffffff', Sum: totaltest[3], data: CasesDailyList.map(record => {
                var day = record["Date"].split('.')[0]
                if (day[0] === '0') { day = day.slice(1) }
                return { x: record["Date"], y: record['yTotal'] }

            })
        });
        seriesInsCDaily.push({
            id: 'Total Cases', color: '#ffffff', Sum: totaltest[4], data: CasesDailyList.map(record => {
                var day = record["Date"].split('.')[0]
                if (day[0] === '0') { day = day.slice(1) }
                return { x: record["Date"], y: record['yTotal'] }

            })
        });
        seriesRefusalDaily.push({
            id: TextLanguage["RefusedTotal"][lang], color: '#e00000b3', data: CasesDailyList.map(record => {
                var day = record["Date"].split('.')[0]
                if (day[0] === '0') { day = day.slice(1) }
                return { x: record["Date"], y: (record['yTotal'] - record['yReferred']) }
            })
        });

        setseriesAll([[seriesCaseDaily, seriesCaseSum], [seriesHospDaily, seriesHospSum], [seriesRefusalDaily, seriesRefusalSum],
        [seriesRegionDaily, seriesRegionSum], [seriesTCDaily, seriesTCSum],
        [seriesInsCDaily, seriesInsCSum], [seriesOperatorDaily, seriesOperatorSum]]);

        console.log(seriesAll)

        setdataProcessed(true);

    }, [retrievedData, filterDateRange])






    // ========== Scroll Switch ================


    // Custom hook to get the window dimensions

    const [legendToggle, setlegendToggle] = useState(window.innerWidth >= 1200);

    const useWindowDimensions = () => {
        const [windowDimensions, setWindowDimensions] = useState({
            width: window.innerWidth,
            height: window.innerHeight
        });

        useEffect(() => {
            const handleResize = () => {
                setlegendToggle(window.innerWidth >= 1200);
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            };

            window.addEventListener('resize', handleResize);

            // Cleanup function to remove the event listener
            return () => window.removeEventListener('resize', handleResize);
        }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

        return windowDimensions;
    };



    const { width, height } = useWindowDimensions();





    const areaGenerator = area()
        .x(d => d.x)
        .y0(height - remToPx(355 / 16)) // Set the bottom line for the area 125 = sum of top,bottom margins // no idea where 150 is from
        .y1(d => d.y)
        .curve(curveMonotoneX); // This defines the top boundary of the area.



    const lineGenerator = (line()
        .x(d => d.x)
        .y(d => d.y)
        .curve(curveMonotoneX));


    const [highlightedId, setHighlightedId] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [tooltip, setTooltip] = useState({ display: 'none', top: 0, left: 0, text: '' });

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const colorScale = scaleSequential(t => {
        const color = interpolateBlues(t);
        return rgb(color).formatHex();
      }).domain([1, 0]);


   const generateNivoChartData = NivoChartDataGenerator(colorScale);





    const [ChartTypeToggle, setChartTypeToggle] = useState(false); // Line (false) or Pie (true) Chart

    function DataChartRender(index) {

        return (


            dataProcessed && seriesAll[0][0][0].data ? <>

                {index > 0 ? <BreakdownCaseNivoChart
                dataProcessed={dataProcessed}
                ListTotals={ListTotals}
                lang={lang}
                height={height}
                setvisibleBool={setvisibleBool}
                visibleBool={visibleBool}
                seriesAll={seriesAll}
                setTooltip={setTooltip}
                tooltip={tooltip}
                setHighlightedId={setHighlightedId}
                highlightedId={highlightedId}
                pieAngle={pieAngle}
                dateRangeTitles={dateRangeTitles}
                filterDateRange={filterDateRange}
                xtickMax={xtickMax}
                legendToggle={legendToggle}
                activeChart={activeChart}
                //Title={TitleArray[index]}
                index={index}
                chartTypeToggle={ChartTypeToggle}
                /> :

                    // For the first series (Total Case Data) create custom line graph (no legend, only two lines)

                    <>
                        <TotalCaseLineChart
                        index={index}
                        activeChart={activeChart}
                        seriesAll={seriesAll}
                        xDateFormatter={xDateFormatter}
                        filterDateRange={filterDateRange}
                        xtickMax={xtickMax}
                        dateRangeTitles={dateRangeTitles}
                        lang={lang}
                        />
                        <TotalCasePieChart
                        index={index}
                        activeChart={activeChart}
                        seriesAll={seriesAll}
                        ListTotals={ListTotals}
                        />
                    </>}
            </> : null

        )


    };

    const MinimapElements = useRef(null);

    MinimapElements.current = TitleArray.map((title, index) => {

        return (
            <Box
                className="minimapElementContainer"
                left={-scrollPos + 150 * index + 75}
                margin={'10px 15px 20px 10px'}
                sx={{ transition: 'all 0.35s ease-in-out' }}
            >
                <Typography sx={{ whiteSpace: 'nowrap', fontSize: 18 }}>{title[lang]}</Typography>
                <BarChartIcon sx={{ fontSize: 80, color: 'orange' }} />
            </Box>
        )

    })



    //backgroundImage: "linear-gradient(to bottom, rgb(49,80,125), hsl(216, 44%, 25%))"
    return (


        <div className='DisplayDataMain' >

            <div
                className='AppBar'
                style={{
                    height: 80, alignItems: 'center',
                    display: 'flex',
                    backgroundImage:
                        "linear-gradient(to bottom, rgb(49,80,125), hsl(216, 44%, 25%))",
                    zIndex: 1000
                }}
            >
                <Button

                    aria-controls={'routerMenu'}
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{
                        m: '0px 0px 0px 0px',
                        padding: '0px 0px 0px 0px',
                        height: '48px',
                        alignItems: 'center',
                        alignSelf: 'center'
                    }}
                    onClick={handleProfileMenuOpen}
                >
                    <MenuIcon />

                </Button>
                <Typography color="inherit" variant="h6" align='left' component="div" sx={{ alignSelf: 'center', flexGrow: 1, whiteSpace: 'nowrap' }}>
                    <span style={{ textShadow: "0px 0px 8px rgba(35,145,255,0.9)", color: 'white' }}>{TextLanguage["DataPageName"][lang]}</span>
                    {
                        //Title Here
                    }


                </Typography>


                <div
                    style={{
                        width: "100%",
                        display: 'flex',
                        minWidth: "100px",
                        alignContent: 'center',
                        justifyContent: 'center',
                        margin: "0px 25px 0px 25px"
                    }}
                >
                    {(retrievedData && dateList.length > 1) ?
                        <DateSlider
                            sx={{ minWidth: "150px" }}
                            step={2}
                            marks={

                                window.innerWidth > 1200 ?
                                    [
                                        { value: 0, label: `${dateList[0]}` },
                                        { value: Math.floor((dateList.length - 1) / 4), label: `${dateList[Math.floor((dateList.length - 1) / 4)].split('.')[1]}-${dateList[Math.floor((dateList.length - 1) / 4)].split('.')[2]}` },
                                        { value: Math.floor((dateList.length - 1) / 2), label: `${dateList[Math.floor((dateList.length - 1) / 2)].split('.')[1]}-${dateList[Math.floor((dateList.length - 1) / 2)].split('.')[2]}` },
                                        { value: 3 * (Math.floor((dateList.length - 1) / 4)), label: `${dateList[3 * Math.floor((dateList.length - 1) / 4)].split('.')[1]}-${dateList[3 * Math.floor((dateList.length - 1) / 4)].split('.')[2]}` },
                                        { value: dateList.length - 1, label: `${dateList[dateList.length - 1]}` }]
                                    :
                                    [
                                        { value: 0, label: `${dateList[0]}` },
                                        { value: Math.floor((dateList.length - 1) / 2), label: `${dateList[Math.floor((dateList.length - 1) / 2)]}` },
                                        { value: dateList.length - 1, label: `${dateList[dateList.length - 1]}` }
                                    ]


                            }
                            getAriaLabel={() => 'Minimum distance shift'}
                            value={filterDateRange}
                            min={0}
                            max={dateList.length - 1}
                            onChange={handleDateSliderChange}
                            valueLabelFormat={valueLabelFormat}
                            valueLabelDisplay="auto"
                            //valueLabelFormat={valueLabelFormat}
                            disableSwap
                        /> : null
                    }

                </div>

                <Button
                    sx={{ color: 'rgba(225,0,0,1)', marginRight: 5 }}
                    disabled={activeChart === 0}
                    onClick={() => {
                        setChartTypeToggle(!ChartTypeToggle);


                        if (!ChartTypeToggle) {
                            setTimeout(() => {
                                setpieAngle(0);

                            }, 25);

                        }
                        else {
                            setpieAngle(105);
                        }

                    }}
                >

                    {
                        (ChartTypeToggle ? <StackedLineChartRoundedIcon sx={{ fontSize: remToPx(55 / 20) }} />
                            :
                            <PieChartRoundedIcon sx={{ rotate: '15deg', fontSize: remToPx(55 / 20) }} />)
                    }

                </Button>



                <LanguageSelector
                lang={lang}
                setlang={setlang}
                />


            <PageMenu
                lang={lang}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                currentPage={currentPage}
            />
            </div>


            <span
                className='DataDrawer'
                style={{
                    top: TotalCaseBarPos,
                    transition: 'all 0.8s ease-out',
                    position: 'absolute',

                    //borderBottom: '4px solid rgb(49,80,125)',
                    height: drawerSize,
                    zIndex: 2,
                    display: 'flex',
                    fontSize: `${remToPx(28 / 16)}px`,
                    fontWeight: 600, flexWrap: 'nowrap',
                    textAlign: 'center'
                }}>


                {` ${TextLanguage["Cases"][lang]}: ${ListTotals[4]} - `}
                {` ${TextLanguage["Referred"][lang]}: `}
                <span style={{ whiteSpace: 'break-spaces', color: '#20c11ab3' }}>{` ${ListTotals[0]} `}</span>
                {`- ${TextLanguage["Refused"][lang]}: `}
                <span style={{ whiteSpace: 'break-spaces', color: '#e00000b3' }}>{` ${ListTotals[1]} `}</span>

            </span>

            <div className='ChartContainer'>
                <span style={{
                    paddingTop: 20 + TotalCaseBarPos, transition: 'all 0.4s ease-in-out', fontSize: remToPx(32 / 16), textShadow: '0px 0px 20px rgba(31, 128, 255, 0.88)',
                    fontWeight: 600, flexWrap: 'nowrap', textAlign: 'center', position: 'block', zIndex: 5, width: '100%'
                }}>
                    {
                        dataProcessed && seriesAll.length > 1 ? TitleArray[activeChart][lang] : null
                    }
                </span>
                {
                    dataProcessed && seriesAll.length > 1 ? DataChartRender(activeChart) : null
                }
            </div>


            <div
                style={{
                    display: tooltip.display,
                    pointerEvents: 'none',
                    position: 'fixed',
                    top: tooltip.top,
                    left: tooltip.left,
                    backgroundColor: 'rgba(34, 34, 37, 0.9)',
                    border: '1px solid black',
                    borderRadius: '10px',
                    padding: '5px',
                    fontSize: '20px'
                }}
            >
                {tooltip.text}
            </div>


            {
                // ============Minimap Start==================
                /*
            <div
                className='minimapContainer'
                style={{

                    opacity: minimapReveal ? 1 : 0,
                    visibility: minimapReveal ? 'visible' : 'hidden',
                    position: 'fixed',
                    top: '100px',
                    left: '25px',
                    width: remToPx(450 / 18),
                    height: remToPx(125 / 18),
                    background: 'rgba(45, 45, 55, 0.75)',
                    border: '6px solid rgba(49,80,125,0.4)',
                    boxShadow: '-4px 4px 24px 0px black',
                    borderRadius: '15px',
                    display: 'block',
                    overflow: 'clip',
                    transition: 'all 0.4s ease-in-out',
                    flex: 1,
                    zIndex: 50,
                }}>
                <div

                    style={{
                        flex: 1,
                        zIndex: 50,
                        border: '1px solid gray',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'row',

                    }}
                >
                    {//MinimapElements.current
                    }
                </div>
            </div>
                */
            }



        </div>





    )

}


export default DataDashboard;






