import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';

export const Accordion = styled((props) => ( // Accordion Settings
    <MuiAccordion disableGutters elevation={12} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));
