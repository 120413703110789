export function NivoChartDataGenerator(colorScale) {
    return (DailyList) => {

        //let firstColumnName = Object.keys(DailyList[0])[0]; // Pick out label column string
        let seriesDaily = DailyList.filter(record => { return record["Name"]; }) // drop out null rows
            .map((record, index) => {
                const data = [];
                const Sum = record["Sum"];
                const color = colorScale(index / DailyList.length);
                const id = record["Name"];

                for (const key in record) {


                    if (key !== "Name" && key !== 'Sum') { // Grab the month/year from the first line

                        var day = key.split('.')[0];
                        if (day[0] === '0') {
                            day = day.slice(1);
                        }

                        //data.push({ "x": day, "y": record[key] });
                        data.push({ "x": key, "y": record[key] });
                    }
                }

                return { id, color, data, Sum };
            });





        let seriesSum = DailyList.filter(record => { return record["Name"]; }) // drop out null rows
            .map((record, index) => {
                const color = colorScale(index / DailyList.length);
                const id = record["Name"];
                const label = record["Name"];
                const value = record["Sum"];


                return { id, label, color, value };
            });




        return [seriesDaily, seriesSum];
    };
}
