import { styled } from '@mui/material/styles';
import { Tabs } from '@mui/material';

export const FormTabs = styled(Tabs)(({ theme }) =>({
    background: theme.palette.dark[2],
    height:'100%',
    borderRight: `4px solid ${theme.palette.mainColor.Dark}`,
    borderLeft: `4px solid ${theme.palette.mainColor.Dark}`,
    '& .MuiTabs-flexContainer':{
        alignContent: 'center',
        justifyContent: 'center',
        height: '100%',
    },
    '& .MuiTabs-indicator': {
        background: theme.palette.mainColor.LightTransp,
        bottom: -2,
        height: 4,
        borderRadius: '12px',
        boxShadow: `0px -2px 25px 4px ${theme.palette.mainColor.LightTransp}`
    },
    position: 'relative', // Add this line
    '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0, // Adjust as needed
        left: 0, // Adjust as needed
        width: "100%", // Adjust as needed
        height: 0, // Adjust as needed
        borderRadius: '0px',
        boxShadow: `0px 0px 10px 6px ${theme.palette.dark.transparent[1]}`,
    },
}));
