import { useReducer, useEffect, useState } from 'react';
import {
    Box,
    Modal,
    Button,
    Chip,
    MenuItem
} from '@mui/material';
import TextLanguage from '../data/TextLanguage.json';
import { ModalStyle } from '../StyledComponents/ModalStyle';
import { FormField } from '../StyledComponents/FormField';

import AddIcon from '@mui/icons-material/Add';


import { useUpdateHospitalMutation } from './hooks/useUpdateHospitalMutation';

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD':
            return {
                ...state,
                [action.fieldName]: action.payload,
            };

        case 'REPLACE':
            return {
                ...action.tempval
            };
        case 'REMOVE_ARRAY_ITEM':
            return {
                ...state,
                [action.fieldName]: state[action.fieldName].filter((item) => item !== action.payload)
            };
        case 'PUSH_ARRAY_ITEM':

            if (state[action.fieldName].includes(action.payload) || action.payload === null || action.payload === '') {
                return state;
            }
            return {
                ...state,
                [action.fieldName]: [...state[action.fieldName], action.payload]
            };
        case 'SET_FIRST_ARRAY_ITEM':
            return {
                ...state,
                [action.fieldName]: [action.payload, ...state[action.fieldName].filter((item) => item !== action.payload)]
            };
        default:
            return state;
    }


}

const initialState = {
    "Name": '',
    "Notes": '',
    "Phone": '',
    "Fax": '',
    "Email": '',
    "Region": [''],
    "Lat": 36,
    "Lng": 30,
    "MedGroup": '',
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export const HospitalUpdateForm = ({ setsnackbarStatus,
    openModal,
    setOpenModal,
    regionDataQuery,
    data, lang }) => {

    const contactModalData = openModal >= 0 ? data[openModal] : null;

    const handleModalClose = () => { setOpenModal(-1) };

    const hospitalMutation = useUpdateHospitalMutation({ modal: true, setOpenModal, setsnackbarStatus });

    const [hospitalUpdater, dispatch] = useReducer(reducer, initialState);

    const [addRegion, setAddRegion] = useState('');

    const handleFieldChange = (fieldName) => (e) => {

        dispatch({
            type: 'SET_FIELD',
            fieldName: fieldName,
            payload: e.target.value,
        });

    };

    const handleReplaceHospitalUpdater = (input) => {
        dispatch({
            type: 'REPLACE',
            tempval: input,
        });
    }

    useEffect(() => {
        handleReplaceHospitalUpdater(contactModalData);
    }, [contactModalData])


    const handleRegionSelection = () => {

        dispatch({
            type: 'PUSH_ARRAY_ITEM',
            fieldName: 'Region',
            payload: addRegion,
        });

    }

    const handleRegionRemove = (region) => () => {
        dispatch({
            type: 'REMOVE_ARRAY_ITEM',
            fieldName: 'Region',
            payload: region,
        });
    }

    const handleSwitchRegionMain = (region) => () => {

        dispatch({
            type: 'SET_FIRST_ARRAY_ITEM',
            fieldName: 'Region',
            payload: region,
        });


    }


    const handleSubmitEdit = () => {

        const updaterData = {
            ...hospitalUpdater,
            Lng: parseFloat(hospitalUpdater.Lng),
            Lat: parseFloat(hospitalUpdater.Lat),
        }

        hospitalMutation.mutate({ updaterData: updaterData });
    }

    return (contactModalData && openModal >= 0 ? <Modal
        //open={openModal == data.index}
        open={openModal >= 0}
        onClose={handleModalClose}
        id={`InfoModal-${contactModalData.index}`}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={{ ...ModalStyle, width: '90%', overflowY: 'scroll' }}>

            <div className='HotelEditFormGroup' style={{ marginTop: 0 }} >



                <div className='HospitalEditFormFieldTopGroup'  >

                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '1rem' }}>



                        <div className='form-row'>

                            <FormField
                                label={"Name"}
                                value={hospitalUpdater.Name}
                                onChange={handleFieldChange('Name')} />
                            <FormField
                                label={"Phone Number"}
                                value={hospitalUpdater.Phone}
                                onChange={handleFieldChange('Phone')} />
                        </div>

                        <div className='form-row'>

                            <FormField
                                label={"Latitude"}
                                value={hospitalUpdater.Lat}
                                onChange={handleFieldChange('Lat')} />
                            <FormField
                                label={"Longitude"}
                                value={hospitalUpdater.Lng}
                                onChange={handleFieldChange('Lng')} />

                        </div>
                        <div className='form-row'>

                            <FormField
                                label={"Fax"}
                                value={hospitalUpdater.Fax}
                                onChange={handleFieldChange('Fax')} />
                            <FormField
                                label={"Email"}
                                value={hospitalUpdater.Email}
                                onChange={handleFieldChange('Email')} />
                        </div>




                    </div>

                    <div className='form-row'>

                        <div className='RegionSelectorContainerForm'>



                            <div className='RegionSelectorFieldRow'>
                                <FormField
                                    id="select-region"
                                    select
                                    value={addRegion}
                                    onChange={(e) => setAddRegion(e.target.value)}
                                    defaultValue={''}
                                >
                                    <MenuItem value={null}>None</MenuItem>
                                    {regionDataQuery?.data?.data?.map((option) => (
                                        <MenuItem key={`addregion-${option?.Region}`} value={option?.Region}>
                                            {option?.Region}
                                        </MenuItem>
                                    ))}
                                </FormField>
                                <Button
                                    onClick={handleRegionSelection}
                                    sx={{ background: 'rgb(49,80,125)' }}>
                                    <AddIcon sx={{ color: 'rgb(125,188,255)' }} />
                                </Button>
                            </div>
                            <div style={{
                                display: 'flex', flexDirection: 'row', gap: '0.5rem',
                                flexWrap: 'wrap',
                            }}>

                                {

                                    hospitalUpdater?.Region?.map((region, index) => {
                                        return <Chip
                                            onClick={handleSwitchRegionMain(region)}
                                            onDelete={handleRegionRemove(region)}
                                            sx={{
                                                background: index === 0 ? 'rgb(201, 99, 3)' : 'rgb(49,80,125)',
                                                '&:hover': { background: index === 0 ? 'rgb(251, 131, 19)' : 'rgb(57, 93, 147)' },
                                                color: 'white', width: 'min-content'
                                            }}
                                            key={`HospSelection-${region}`}
                                            label={region} />
                                    })

                                }
                            </div>

                        </div>


                    </div>




                </div>













                <div className='form-row' style={{ justifyContent: 'flex-start', paddingTop: '2rem' }}>


                    <FormField
                        label="Notes"
                        multiline
                        rows={2}
                        value={hospitalUpdater.Notes}
                        onChange={handleFieldChange('Notes')}
                        sx={{
                            width: '40%',
                            maxWidth: '40%'
                        }}

                    />
                </div>






                <div style={{ alignItems: 'center', paddingTop: '2rem', width: '25%' }}>
                    <Button sx={{
                        background: 'rgb(49,80,125)', '&:hover': { background: 'rgb(57, 93, 147)' },
                        color: 'white', width: '100%', maxWidth: '100%',
                        padding: '5px 15px',
                        fontWeight: 'bold'
                    }}
                        onClick={handleSubmitEdit}>
                        Submit
                    </Button>
                </div>

            </div>


        </Box>
    </Modal> : null);
}
