import React from 'react';
import {
    Box, Modal, Divider,
    Typography
} from '@mui/material';
import TextLanguage from '../data/TextLanguage.json';
import { ModalStyle } from '../StyledComponents/ModalStyle';



const textParser = (text) => {  // Fix this !!!
    if (text === null) { return null } else {
        const regex = /(\D+:\S+)/g;
        let boldtext = '';
        let afterboldtext = '';
        let parsedtext = text.replace(/ \//g, '\n').replace(/\//g, '\n').replace(/\/ /g, '\n');
        let matchesText = parsedtext.match(regex);
        if (matchesText) {
            let match = matchesText[0];
            [boldtext, afterboldtext] = match.split(':');
        }
        let othertext = parsedtext.split(boldtext)[0];

        return text

        /*
        return ((matchesText) ? (
            <>
                <span>{othertext}</span>
                <span style={{ fontWeight: 'bold', color: 'rgba(33,194,27,0.7)' }} >
                    {`${boldtext}: `}
                </span>
                <span>
                    {afterboldtext}
                </span>
            </>) : (parsedtext))
        */

    }
}

const titleSize = '1.7rem';
const infoSize = '1.4rem';


export const ContactModal = ({setOpenModal,openModal, data: closestHospitalData, lang}) => {

    const contactModalData = openModal >= 0 ? closestHospitalData[openModal] : null;
    const parsedphone = contactModalData? textParser(contactModalData?.Phone):null;
    const parsedfax = contactModalData? textParser(contactModalData?.Fax):null;
    const handleModalClose = () => { setOpenModal(-1) };

    return (contactModalData && openModal >= 0 ? <Modal
        //open={openModal == data.index}
        open={(openModal >= 0)}
        onClose={handleModalClose}
        id={`InfoModal-${contactModalData.index}`}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={ModalStyle}>
<div className='ContactModalContainer'>


                <div className='ContactModalTitleContainer'>
                    <Typography sx={{  color: 'red', fontSize: titleSize, fontWeight: 'bold', }} id="modal-modal-title" variant="h6" component="h2">
                        {TextLanguage['Contact Information'][lang]}
                    </Typography>
                    <Typography sx={{  color: 'white', fontSize: infoSize, fontWeight: 'bold',  }} id="modal-modal-title" variant="h7" component="h2">
                        {contactModalData.Name}
                    </Typography>
                </div>

                <div className='ContactModalInfoContainer'>
                    {parsedphone ? (<div><Typography sx={{  color: 'red', fontSize: titleSize }}>{TextLanguage['Phone'][lang]}</Typography>
                        <Divider orientation="vertical" flexItem className='modalDiv' sx={{ width: '100%', height: '2px', backgroundColor: 'rgba(255, 255, 255,0.15)' }}></Divider>
                        <Typography sx={{ marginTop:'0.75rem',color: 'white', fontSize: infoSize,  whiteSpace: 'pre-line' }}>{parsedphone}</Typography></div>) : null}

                    {parsedfax ? (<div><Typography sx={{ color: 'red',fontSize: titleSize }}>{TextLanguage['Fax'][lang]}</Typography>
                        <Divider orientation="vertical" flexItem className='modalDiv' sx={{ width: '100%', height: '2px', backgroundColor: 'rgba(255, 255, 255,0.15)' }}></Divider>
                        <Typography sx={{marginTop:'0.75rem',  color: 'white', fontSize: infoSize,  }}>{parsedfax}</Typography></div>) : null}

                    {contactModalData?.Email ? (<div><Typography sx={{  color: 'red', fontSize: titleSize }}>{TextLanguage['Email'][lang]}</Typography>
                        <Divider orientation="vertical" flexItem className='modalDiv' sx={{ width: '100%', height: '2px', backgroundColor: 'rgba(255, 255, 255,0.15)' }}></Divider>
                        <Typography sx={{ marginTop:'0.75rem', color: 'white', fontSize: infoSize,  }}>{contactModalData?.Email}</Typography></div>) : null}


                </div>
</div>


        </Box>
    </Modal>:null);
}
