import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const NewPatientButton = styled(Button)(({ theme }) => ({
    color: theme.palette.text.primary,
    borderRadius: 0,
    height: '50px',
    width: '175px',
    alignItems: 'center',
    alignSelf: 'center',
    background: 'transparent',
    fontWeight: 'bold',
    fontSize: '0.85rem',
    border: `1px solid ${theme.palette.shadow[1]}`,
    borderRadius: '8px',
    boxShadow: `inset 0px 0px 20px 0px ${theme.palette.shadow[2]}, 0px 0px 12px -2px ${theme.palette.shadow[0]}`,
    zIndex: 200,


    '&:hover, &.Mui-focusVisible': {
        transition: '0.25s ease-in-out all',
        background: theme.palette.gradient.glowButton,
        boxShadow: `inset 0px 0px 18px -2px ${theme.palette.buttonGlow[0]}`,
        zIndex: 50,
    },


}));
