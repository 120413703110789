import remToPx from '../../functions/remToPx';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';

/*   Hex Color Editor, modifies alpha channel and returns as rgba

const addAlpha = (hex, alpha) => {
    // Check if the color is in RGBA format
    if (hex.startsWith('#') && hex.length === 9) {
        // Extract the RGB and A parts
        const rgbHex = hex.slice(0, 7);
        const aHex = hex.slice(7, 9);

        // Convert the alpha value to decimal
        const aDec = parseInt(aHex, 16) / 255;
        // Multiply the existing alpha by the new alpha
        alpha *= aDec;
        // Convert the alpha back to hexadecimal
        const newAHex = Math.round(alpha * 255).toString(16).padStart(2, '0');

        // Combine the RGB and new A parts
        hex = rgbHex + newAHex;
    }

    // Convert the color to RGBA
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})` : hex;
};

*/
//const remToPx = (rem) => rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
export const DateSlider = styled(Slider)({
    color: 'rgb(125,188,255)',
    alignContent: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    marginLeft: 55,
    marginRight: 55,
    zIndex: 5,
    height: 8,
    "& .MuiSlider-markLabel": {
        fontSize: "1rem"
    },
    '& .MuiSlider-track': {
        border: 'none',
        background: 'linear-gradient(to bottom,rgba(125,188,255,0.5),rgb(49,80,125))',
    },
    '& .MuiSlider-thumb': {
        height: 30,
        width: 12,
        borderRadius: 32,
        backgroundColor: 'rgb(61, 110, 148)',
        border: '2px solid rgba(255,255,255,0.2)',
        boxShadow: 'inherit',
        //'&:hover': {background:'red'},
        '&.Mui-active': {
            background: 'rgb(49,80,125)',

            boxShadow: '0px 0px 4px 4px rgb(125,188,255)',
        },
        '&:before': {
            display: 'none',
        },
    },
    '& .MuiSlider-rail': {
        background: 'rgba(4, 5, 42, 0.8)',
    },

    '& .MuiSlider-valueLabel': {
        '&:before': {
            transform: 'translate(-50%, -150%) rotate(0deg) scale(0)',
        },
        transition: '0.25s ease-in-out 0s',
        zIndex: -25,
        borderRadius: 8,
        top: 125,
        right: 10,
        lineHeight: 1.5,
        fontSize: remToPx(1),
        background: 'unset',
        padding: 10,
        width: 120,
        height: 48,
        backdropFilter: "blur(6px)",
        WebkitBackdropFilter: "blur(6px)",
        backgroundColor: 'rgba(49,80,125,0.3)',
        border: '4px solid rgb(28, 54, 95)',
        boxShadow: '-4px 4px 4px 4px rgba(34, 34, 37, 0.8)',

        transform: 'translate(50%, -200%) rotate(0deg) scale(1)',
        backgroundColor: 'rgba(49,80,125,0.2)',
        opacity: 0,
        transformOrigin: 'top',

        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -140%) rotate(0deg) scale(1)',
            opacity: 1,
        },
        '& > *': {
            // transform: 'rotate(0deg)',
        },
    },
});
