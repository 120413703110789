
import { useReducer, useEffect } from 'react';

import {
    Button, MenuItem, Typography,

} from '@mui/material';
import { FormField } from '../StyledComponents/FormField';
import { useUpdateHotelMutation } from './hooks/useUpdateHotelMutation';


function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD':
            return {
                ...state,
                [action.fieldName]: action.payload,
            };

        case 'REPLACE':
            return {
                ...action.tempval
            };
        default:
            return state;
    }
}

const initialState = {
    "Name": null,
    "Area": null,
    "Phone": null,
    "Fax": null,
    "Reference": null,
    "Contract": null,
    "ContractPhoneNotes": null,
    "Region": null,
    "Lat": 36.8685887,
    "Lng": 30.6484849,
    "NameComm": null,
    "ReferenceComm": null,
    "ContractComm": null,
    "ContractPhoneNotesComm": null,
    "AreaComm": null




};


export const HotelUpdateForm = ({setsnackbarStatus, hospitalData, selectedValue, setSelectedValue, setFormModalOpen }) => {


    const [hotelUpdater, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (fieldName) => (e) => {

        dispatch({
            type: 'SET_FIELD',
            fieldName: fieldName,
            payload: e.target.value,
        });

    };


    const hotelUpdateMutation = useUpdateHotelMutation({ searchMode:true ,setSelectedValue, setFormModalOpen,setsnackbarStatus  });

    const handleReplaceHotelUpdater = (input) => {
        dispatch({
            type: 'REPLACE',
            tempval: input,
        });
    }

    useEffect(() => {
        handleReplaceHotelUpdater(selectedValue);
    }, [selectedValue])


    const handleSubmitEdit = () => {
        hotelUpdateMutation.mutate({ updaterData: hotelUpdater });
    }


    return <div className='HotelEditFormContainer'>
        <div className='HotelEditFormTitle'>
            <Typography variant='h5' sx={{ fontSize: '2.2rem', color: 'white', fontWeight: 'bolder' }}>
                Hotel Update Form
            </Typography>
        </div>


            <div className='HotelEditFormGroup' >
                <div className='form-row'>

                    <FormField
                        label={"Name"}
                        value={hotelUpdater.Name}
                        onChange={handleFieldChange('Name')} />
                    <FormField
                        label={"Phone Number"}
                        value={hotelUpdater.Phone}
                        onChange={handleFieldChange('Phone')} />
                </div>

                <div className='form-row'>

                    <FormField
                        id="outlined-select-contractedHosp"
                        select
                        value={hotelUpdater.Contract}
                        onChange={handleFieldChange('Contract')}
                        defaultValue={hotelUpdater.Contract}
                        helperText="Select Contracted Hospital"
                        sx={{width: '100%', maxWidth: '100%'}}
                    >
                        <MenuItem value={null}>None</MenuItem>
                        {hospitalData?.map((option) => (
                            <MenuItem key={`Cont-Hosp-Opt-${option?.Name}`} value={option?.Name}>
                                {option?.Name}
                            </MenuItem>
                        ))}
                    </FormField>

                </div>
                <div className='form-row'>

                    <FormField
                        label={"Fax"}
                        value={hotelUpdater.Fax}
                        onChange={handleFieldChange('Fax')} />
                    <FormField
                        label={"Reference"}
                        value={hotelUpdater.Reference}
                        onChange={handleFieldChange('Reference')} />
                </div>
                <div className='form-row'>

                    <FormField
                        label="Notes"
                        multiline
                        rows={2}
                        value={hotelUpdater.NameComm}
                        onChange={handleFieldChange('NameComm')}
                        sx={{width: '100%', maxWidth: '100%'}}
                        />
                </div>

                <div style={{ marginTop: 'auto',  alignItems: 'center', paddingTop: '1rem', width:'100%' }}>
                    <Button sx={{ background: 'rgb(49,80,125)', '&:hover': {
                        background: 'rgb(57, 93, 147)',
                    },
                    color: 'white', width: '100%', maxWidth: '100%',
                     padding: '5px 15px',
                    fontWeight: 'bold' }}
                        onClick={handleSubmitEdit}>
                        Submit
                    </Button>
                </div>

            </div>



    </div>;
}
