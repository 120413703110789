
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config';

export const useUpdateUserMutation = ({
   setsnackbarStatus
}) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ updaterData, oldRowData }) => {


            return axios.put(`${config.API_URL}/operator/updateUserInfo`, updaterData, {
                withCredentials: true,
            });
        },
        onSuccess: (data, variables) => {

            queryClient.invalidateQueries({ queryKey: ['userData'] });

        },
        onError: (error, variables) => {
            console.log(variables);
            setsnackbarStatus({ open: true, message: 'There was an error during the update', severity: 'error' });
            queryClient.setQueryData(['userData'], (prevData) => {
                const updatedData = prevData?.data?.map((user) =>
                user._id === variables.oldRowData._id ? variables.oldRowData : user
                );
                return { ...prevData, data: updatedData };
            });

        }
    });
}
