import * as React from 'react';

import { TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Box } from "@mui/material";
import { matchSorter } from 'match-sorter';

import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';


import TextLanguage from '../data/TextLanguage.json';
import useLocalStorage from '../../hooks/useLocalStorage.jsx';

import PropTypes from 'prop-types';

import { FixedSizeList } from 'react-window';
import Typography from '@mui/material/Typography';

import remToPx from '../../functions/remToPx.jsx';
import { Autocomplete } from '../StyledComponents/Autocomplete.jsx';
import { StyledPopper } from '../StyledComponents/StyledPopper.jsx';


const itemSize = remToPx(3);


function LiveSearch({
    setLng, setLat,
    setSelectedValue,
    selectedValue,
    lang,
    hotelData
}) {

    const theme = useTheme();




    function renderRow(props) {
        const { data, index, style } = props;
        const dataSet = data[index];
        const key = `${dataSet[1].Name}-${index}`;

        return (
            <Box
                key={key}
                sx={{
                    position: 'absolute',
                    top: style.top + itemSize / 2,
                    width: '100%',
                    alignItems: 'center', // Center items horizontally
                    justifyContent: 'center', // Center items vertically
                    color: 'white', marginBottom: 2, paddingLeft: 4, paddingBottom: 10, height: itemSize,

                    display: 'flex', flexDirection: 'column'
                }}
                component="li" {...dataSet[0]}
                className="searchBox">
                <Box key={`${key}-BlockBox`} sx={{ display: 'inline-block', width: '100%' }}>
                    <span key={`${key}-span`} style={{ fontSize: 22 }}>{dataSet[1].Name} </span>
                    <Chip key={`${key}-chip`} variant="outlined"
                        sx={{
                            border: '1px solid var(--color-light-gray-tint)',
                            color: 'white',
                            '& .MuiChip-label': { paddingLeft: 2, paddingRight: 2 },
                            height: itemSize / 2,
                            background: theme.palette.dark[4],
                            fontWeight: 900, fontSize: remToPx(10 / 16), margin: 2,
                            position: 'absolute', top: -itemSize * 0.55
                        }}
                        label={dataSet[1].Area.toUpperCase()} />
                </Box>
                <Divider sx={{ position: 'absolute', left: 0, top: itemSize / 2 - 6, width: '100%', height: 2, background: theme.palette.dark[2] }}></Divider>
            </Box>
        )
    }

    const OuterElementContext = React.createContext({});

    const OuterElementType = React.forwardRef((props, ref) => {
        const outerProps = React.useContext(OuterElementContext);
        return <div ref={ref} {...props} {...outerProps} />;
    });

    const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
        const { children, ...other } = props;
        const itemData = [];
        children.forEach((item) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        });

        const itemCount = itemData.length;



        const listRef = React.useRef();

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <FixedSizeList
                        key={`VarList-${itemCount}`}
                        style={{ overflow: 'auto', height: '100%', margin: '0px !important', padding: '0px !important', background: theme.palette.dark[0] }}
                        itemData={itemData}
                        height={800}
                        width="100%"
                        ref={listRef}
                        outerElementType={OuterElementType}
                        innerElementType={"ul"}
                        itemSize={(remToPx(2.5))}
                        overscanCount={50}
                        itemCount={itemCount}
                    >
                        {renderRow}
                    </FixedSizeList>
                </OuterElementContext.Provider>
            </div>
        );
    });

    ListboxComponent.propTypes = {
        children: PropTypes.node,
    };

    const handleAutocompleteChange = (event, value) => {
        setSelectedValue(value);

        if (value && value.Lng) { //If Selected Value is not null and matches an entry
            setLng(value.Lng); // Set the position of the searched hotel
            setLat(value.Lat);

        }
    };


    const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, {
        keys: ['Name', 'Region'],
        base: 10,
        maxRanking: 10,
    });

    return (

        <Autocomplete
            id="size-small-outlined"
            className="autocomp"
            onChange={handleAutocompleteChange}
            size="small"

            //value={selectedValue}
            PopperComponent={StyledPopper}
            getOptionLabel={(hotel) => `${hotel.Name}`}
            options={hotelData}
            filterOptions={filterOptions}
            isOptionEqualToValue={(option, value) =>
                option.Name.toLowerCase() === value.Name.toLowerCase()
            }
            ListboxComponent={ListboxComponent}
            noOptionsText={"No Matches"}
            renderOption={(props, option, state) => [props, option, state.index]}
            renderInput={(params) => <TextField {...params}
                InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                        <>
                            <InputAdornment position="start">
                                <SearchIcon sx={{ marginLeft: '4px' }} />
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                        </>
                    )
                }}
                variant="outlined"
            />}
        />
    );
}


export default LiveSearch;


