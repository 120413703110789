
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';
import dayjs from 'dayjs';

export const usePatientDocUploadMutation = ({ dispatchPatient, setsnackbarStatus, setUploadProgress,  setdateTargetAdmission, setdateTargetDob }) => {

    return useMutation({
        mutationFn: ({ formData }) => {
            console.log(formData);
            return axios.post(
                `${config.API_URL}/table/patientDoc`,
                formData,
                {
                    withCredentials: true,
                    onUploadProgress: progressEvent => setUploadProgress(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                }

            );
        },
        onSettled: (data, error, variables, context) => {
           console.log('data:',data, 'error:', error, 'variables:', variables, 'context:', context);
            if (error){
                setsnackbarStatus(
                    {
                        open: true,
                        message: `Error Uploading File`,
                        severity: 'error'

                    }
                )

            } else if ( data.status === 200) {

                const patientData = data.data;
                Object.keys(patientData).forEach((key) => {
                    if (key === 'dob' && patientData[key] !== null){
                        const parsedDate = new Date(patientData[key]);
                        console.log(parsedDate);
                        dispatchPatient({
                            type: 'SET_FIELD',
                            fieldName: 'dob',
                            payload: parsedDate,
                        });
                        setdateTargetDob(dayjs(parsedDate));
                    } else if (key === 'admissionDate' && patientData[key] !== null) {
                        const parsedDate = new Date(patientData[key]);
                        console.log(parsedDate);
                        dispatchPatient({
                            type: 'SET_FIELD',
                            fieldName: 'admissionDate',
                            payload: parsedDate,
                        });
                        setdateTargetAdmission(dayjs(parsedDate));
                    } else {
                        dispatchPatient({
                            type: 'SET_FIELD',
                            fieldName: key,
                            payload: patientData[key],
                        });
                    }

                })



            setsnackbarStatus(
                {
                    open: true,
                    message: `File Parsed Successfully`,
                    severity: 'success'

                }
            )
        }
        },


    })

}
