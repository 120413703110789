
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../../../config.js";


export const useMapboxKeyQuery = () => {

    async function fetchMapboxAPIKey() {

        return await axios.get(`${config.API_URL}/mapbox/APIKey`, {
            withCredentials: true,
        })

    }
    return useQuery(
        {
            queryKey: ['mapboxAPI'],
            queryFn: fetchMapboxAPIKey,
            staleTime: Infinity, // 6 hours
        }




    )

}