import React from 'react';
import { styled } from '@mui/material/styles';
import { Tab } from '@mui/material';

export const FormTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    marginRight: theme.spacing(1),
    color: theme.palette.text.primary,

    '&:hover': {
        color: theme.palette.mainColor.Sat,
        opacity: 1,
    },
    '&.Mui-selected': {
        color: theme.palette.mainColor.Sat,
        fontWeight: theme.typography.fontWeightMedium,
    },

}));
