import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const GeocodeField = styled(TextField)(({ theme }) => ({
    color: 'white',
    backgroundColor: 'rgba(34, 34, 37, 0.9)',
    background: 'rgb(53, 56, 59)',
    transitionDuration: '125ms',
    borderRadius: 0,
    width: '100%',
    fontSize: 16,
    fontWeight: 'bold',
    boxShadow: 'inset 0px 0px 18px -2px rgba(0, 0, 0, 0.33)',

    transitionProperty: 'background',

    '&:hover, &.Mui-focusVisible': {
        backgroundColor: `rgba(125,188,255,0.02)`,
        background: 'rgb(45, 45, 52)',


        zIndex: 50,
    },

    '.MuiInputBase-formControl': {
        paddingInline: '0.25rem !important',
        paddingBlock: '0.5rem 0.25rem'
    },

    '.MuiInputBase-inputMultiline': {
        paddingInline: '0.5rem 0.5rem !important',
        paddingBlock: '0.25rem !important'
    },
    '.MuiSelect-select': {
        border: 'none !important',
        paddingInline: '0.5rem 2.5rem !important',
        paddingBlock: '0.5rem 0.25rem'
    },
    '.MuiInputBase-input': {
        '&:hover': {
            backgroundColor: 'rgba(125,188,255,0.02)',
            background: 'rgb(45, 45, 52)',
            boxShadow: 'none',
            outline: 'none',
            border: 'none',
        },
        '&:focus': {
            backgroundColor: 'rgba(125,188,255,0.02)',
            background: 'rgb(45, 45, 52)',
            boxShadow: 'none',
            outline: 'none',
            border: 'none',
        }
    },
}));
